//
// THIS FILE IS GENERATED BY '/_set-env-variables.js'

export const environment = {
  configuration: 'test',
  apiUrl: 'https://ibf-test.510.global/api', // API
  useServiceWorker: false, // feature-flags
  geoserverUrl: 'https://ibf-test.510.global/geoserver/ibf-system/wms', // geoserver
  ibfSystemVersion: 'v0.274.6', // version
  ibfVideoGuideUrl: 'https://bit.ly/IBF-video-Zambia', // video guide url
  ibfPdfGuideUrl: 'https://510ibfsystem.blob.core.windows.net/manuals/IBF%20Manual-Zambia-Published.pdf', // video guide url
  applicationInsightsInstrumentationKey: '82a5935b-149a-422f-8222-4f7d53123c98', // application insights instrumentation key
  applicationInsightsUrl: 'https://westeurope-2.in.applicationinsights.azure.com/', // application insights url endpoint
  supportEmailAddress: 'ibf-support@510.global', // email address for support
  whatsNewUrl: 'https://github.com/rodekruis/IBF-system/blob/master/WHAT_IS_NEW_IN_IBF.md', // WHAT_IS_NEW_IN_IBF.md url
};
