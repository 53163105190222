import * as i0 from '@angular/core';
import { Directive, HostListener, Component, ChangeDetectionStrategy, Injectable, Injector, InjectionToken, Inject, Optional, EventEmitter, Attribute, SkipSelf, Input, Output, ViewChild, ContentChild, TemplateRef, APP_INITIALIZER, NgZone, NgModule } from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { __decorate, __awaiter } from 'tslib';
import { fromEvent, Subject, BehaviorSubject } from 'rxjs';
import * as i3 from '@angular/router';
import { NavigationStart, PRIMARY_OUTLET, ActivatedRoute, ChildrenOutletContexts } from '@angular/router';
import { LIFECYCLE_WILL_ENTER, LIFECYCLE_DID_ENTER, LIFECYCLE_WILL_LEAVE, LIFECYCLE_DID_LEAVE, LIFECYCLE_WILL_UNLOAD, isPlatform, getPlatforms, componentOnReady, actionSheetController, alertController, loadingController, menuController, pickerController, modalController, popoverController, toastController, createAnimation, getTimeGivenProgression, createGesture, setupConfig } from '@ionic/core';
export { IonicSafeString, IonicSlides, IonicSwiper, createAnimation, createGesture, getPlatforms, getTimeGivenProgression, iosTransitionAnimation, isPlatform, mdTransitionAnimation } from '@ionic/core';
import { filter, switchMap, distinctUntilChanged } from 'rxjs/operators';
import * as i1 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import { applyPolyfills, defineCustomElements } from '@ionic/core/loader';
const raf = h => {
  if (typeof __zone_symbol__requestAnimationFrame === 'function') {
    return __zone_symbol__requestAnimationFrame(h);
  }
  if (typeof requestAnimationFrame === 'function') {
    return requestAnimationFrame(h);
  }
  return setTimeout(h);
};
const isComponentFactoryResolver = item => {
  return !!item.resolveComponentFactory;
};

// TODO(FW-2827): types
class ValueAccessor {
  constructor(injector, el) {
    this.injector = injector;
    this.el = el;
    this.onChange = () => {
      /**/
    };
    this.onTouched = () => {
      /**/
    };
  }
  writeValue(value) {
    /**
     * TODO FW-2646
     * Change `value == null ? '' : value;`
     * to `value`. This was a fix for IE9, but IE9
     * is no longer supported; however, this change
     * is potentially a breaking change
     */
    this.el.nativeElement.value = this.lastValue = value == null ? '' : value;
    setIonicClasses(this.el);
  }
  handleChangeEvent(el, value) {
    if (el === this.el.nativeElement) {
      if (value !== this.lastValue) {
        this.lastValue = value;
        this.onChange(value);
      }
      setIonicClasses(this.el);
    }
  }
  _handleBlurEvent(el) {
    if (el === this.el.nativeElement) {
      this.onTouched();
      setIonicClasses(this.el);
    }
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.el.nativeElement.disabled = isDisabled;
  }
  ngOnDestroy() {
    if (this.statusChanges) {
      this.statusChanges.unsubscribe();
    }
  }
  ngAfterViewInit() {
    let ngControl;
    try {
      ngControl = this.injector.get(NgControl);
    } catch (_a) {
      /* No FormControl or ngModel binding */
    }
    if (!ngControl) {
      return;
    }
    // Listen for changes in validity, disabled, or pending states
    if (ngControl.statusChanges) {
      this.statusChanges = ngControl.statusChanges.subscribe(() => setIonicClasses(this.el));
    }
    /**
     * TODO FW-2787: Remove this in favor of https://github.com/angular/angular/issues/10887
     * whenever it is implemented.
     */
    const formControl = ngControl.control;
    if (formControl) {
      const methodsToPatch = ['markAsTouched', 'markAllAsTouched', 'markAsUntouched', 'markAsDirty', 'markAsPristine'];
      methodsToPatch.forEach(method => {
        if (typeof formControl[method] !== 'undefined') {
          const oldFn = formControl[method].bind(formControl);
          formControl[method] = (...params) => {
            oldFn(...params);
            setIonicClasses(this.el);
          };
        }
      });
    }
  }
}
/** @nocollapse */
ValueAccessor.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: ValueAccessor,
  deps: [{
    token: i0.Injector
  }, {
    token: i0.ElementRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
ValueAccessor.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: ValueAccessor,
  host: {
    listeners: {
      "ionBlur": "_handleBlurEvent($event.target)"
    }
  },
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: ValueAccessor,
  decorators: [{
    type: Directive
  }],
  ctorParameters: function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  },
  propDecorators: {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['ionBlur', ['$event.target']]
    }]
  }
});
const setIonicClasses = element => {
  raf(() => {
    const input = element.nativeElement;
    const hasValue = input.value != null && input.value.toString().length > 0;
    const classes = getClasses(input);
    setClasses(input, classes);
    const item = input.closest('ion-item');
    if (item) {
      if (hasValue) {
        setClasses(item, [...classes, 'item-has-value']);
      } else {
        setClasses(item, classes);
      }
    }
  });
};
const getClasses = element => {
  const classList = element.classList;
  const classes = [];
  for (let i = 0; i < classList.length; i++) {
    const item = classList.item(i);
    if (item !== null && startsWith(item, 'ng-')) {
      classes.push(`ion-${item.substring(3)}`);
    }
  }
  return classes;
};
const setClasses = (element, classes) => {
  const classList = element.classList;
  classList.remove('ion-valid', 'ion-invalid', 'ion-touched', 'ion-untouched', 'ion-dirty', 'ion-pristine');
  classList.add(...classes);
};
const startsWith = (input, search) => {
  return input.substring(0, search.length) === search;
};
class BooleanValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  writeValue(value) {
    this.el.nativeElement.checked = this.lastValue = value == null ? false : value;
    setIonicClasses(this.el);
  }
  _handleIonChange(el) {
    this.handleChangeEvent(el, el.checked);
  }
}
/** @nocollapse */
BooleanValueAccessorDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: BooleanValueAccessorDirective,
  deps: [{
    token: i0.Injector
  }, {
    token: i0.ElementRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
BooleanValueAccessorDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: BooleanValueAccessorDirective,
  selector: "ion-checkbox,ion-toggle",
  host: {
    listeners: {
      "ionChange": "_handleIonChange($event.target)"
    }
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: BooleanValueAccessorDirective,
    multi: true
  }],
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: BooleanValueAccessorDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ion-checkbox,ion-toggle',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BooleanValueAccessorDirective,
        multi: true
      }]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  },
  propDecorators: {
    _handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  }
});
class NumericValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  _handleIonChange(el) {
    this.handleChangeEvent(el, el.value);
  }
  registerOnChange(fn) {
    super.registerOnChange(value => {
      fn(value === '' ? null : parseFloat(value));
    });
  }
}
/** @nocollapse */
NumericValueAccessorDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: NumericValueAccessorDirective,
  deps: [{
    token: i0.Injector
  }, {
    token: i0.ElementRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
NumericValueAccessorDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: NumericValueAccessorDirective,
  selector: "ion-input[type=number]",
  host: {
    listeners: {
      "ionChange": "_handleIonChange($event.target)"
    }
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: NumericValueAccessorDirective,
    multi: true
  }],
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: NumericValueAccessorDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ion-input[type=number]',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: NumericValueAccessorDirective,
        multi: true
      }]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  },
  propDecorators: {
    _handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  }
});
class RadioValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  // TODO(FW-2827): type (HTMLIonRadioElement and HTMLElement are both missing `checked`)
  _handleIonSelect(el) {
    this.handleChangeEvent(el, el.checked);
  }
}
/** @nocollapse */
RadioValueAccessorDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: RadioValueAccessorDirective,
  deps: [{
    token: i0.Injector
  }, {
    token: i0.ElementRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
RadioValueAccessorDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: RadioValueAccessorDirective,
  selector: "ion-radio",
  host: {
    listeners: {
      "ionSelect": "_handleIonSelect($event.target)"
    }
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: RadioValueAccessorDirective,
    multi: true
  }],
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: RadioValueAccessorDirective,
  decorators: [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'ion-radio',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: RadioValueAccessorDirective,
        multi: true
      }]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  },
  propDecorators: {
    _handleIonSelect: [{
      type: HostListener,
      args: ['ionSelect', ['$event.target']]
    }]
  }
});
class SelectValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  _handleChangeEvent(el) {
    this.handleChangeEvent(el, el.value);
  }
}
/** @nocollapse */
SelectValueAccessorDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: SelectValueAccessorDirective,
  deps: [{
    token: i0.Injector
  }, {
    token: i0.ElementRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
SelectValueAccessorDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: SelectValueAccessorDirective,
  selector: "ion-range, ion-select, ion-radio-group, ion-segment, ion-datetime",
  host: {
    listeners: {
      "ionChange": "_handleChangeEvent($event.target)"
    }
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SelectValueAccessorDirective,
    multi: true
  }],
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: SelectValueAccessorDirective,
  decorators: [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'ion-range, ion-select, ion-radio-group, ion-segment, ion-datetime',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: SelectValueAccessorDirective,
        multi: true
      }]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  },
  propDecorators: {
    _handleChangeEvent: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  }
});
class TextValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  _handleInputEvent(el) {
    this.handleChangeEvent(el, el.value);
  }
}
/** @nocollapse */
TextValueAccessorDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: TextValueAccessorDirective,
  deps: [{
    token: i0.Injector
  }, {
    token: i0.ElementRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
TextValueAccessorDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: TextValueAccessorDirective,
  selector: "ion-input:not([type=number]),ion-textarea,ion-searchbar",
  host: {
    listeners: {
      "ionChange": "_handleInputEvent($event.target)"
    }
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TextValueAccessorDirective,
    multi: true
  }],
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: TextValueAccessorDirective,
  decorators: [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'ion-input:not([type=number]),ion-textarea,ion-searchbar',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TextValueAccessorDirective,
        multi: true
      }]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  },
  propDecorators: {
    _handleInputEvent: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  }
});

/* eslint-disable */
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = val);
      }
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  events.forEach(eventName => instance[eventName] = fromEvent(el, eventName));
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let IonAccordion = class IonAccordion {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonAccordion.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonAccordion,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonAccordion.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonAccordion,
  selector: "ion-accordion",
  inputs: {
    disabled: "disabled",
    mode: "mode",
    readonly: "readonly",
    toggleIcon: "toggleIcon",
    toggleIconSlot: "toggleIconSlot",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonAccordion = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['disabled', 'mode', 'readonly', 'toggleIcon', 'toggleIconSlot', 'value']
})], IonAccordion);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonAccordion,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-accordion',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'mode', 'readonly', 'toggleIcon', 'toggleIconSlot', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonAccordionGroup = class IonAccordionGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
};
/** @nocollapse */
IonAccordionGroup.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonAccordionGroup,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonAccordionGroup.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonAccordionGroup,
  selector: "ion-accordion-group",
  inputs: {
    animated: "animated",
    disabled: "disabled",
    expand: "expand",
    mode: "mode",
    multiple: "multiple",
    readonly: "readonly",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonAccordionGroup = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['animated', 'disabled', 'expand', 'mode', 'multiple', 'readonly', 'value']
})], IonAccordionGroup);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonAccordionGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-accordion-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['animated', 'disabled', 'expand', 'mode', 'multiple', 'readonly', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonApp = class IonApp {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonApp.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonApp,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonApp.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonApp,
  selector: "ion-app",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonApp = __decorate([ProxyCmp({
  defineCustomElementFn: undefined
})], IonApp);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonApp,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-app',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonAvatar = class IonAvatar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonAvatar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonAvatar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonAvatar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonAvatar,
  selector: "ion-avatar",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonAvatar = __decorate([ProxyCmp({
  defineCustomElementFn: undefined
})], IonAvatar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonAvatar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-avatar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBackButton = class IonBackButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonBackButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonBackButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBackButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonBackButton,
  selector: "ion-back-button",
  inputs: {
    color: "color",
    defaultHref: "defaultHref",
    disabled: "disabled",
    icon: "icon",
    mode: "mode",
    routerAnimation: "routerAnimation",
    text: "text",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBackButton = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'defaultHref', 'disabled', 'icon', 'mode', 'routerAnimation', 'text', 'type']
})], IonBackButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonBackButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-back-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'defaultHref', 'disabled', 'icon', 'mode', 'routerAnimation', 'text', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBackdrop = class IonBackdrop {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionBackdropTap']);
  }
};
/** @nocollapse */
IonBackdrop.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonBackdrop,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBackdrop.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonBackdrop,
  selector: "ion-backdrop",
  inputs: {
    stopPropagation: "stopPropagation",
    tappable: "tappable",
    visible: "visible"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBackdrop = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['stopPropagation', 'tappable', 'visible']
})], IonBackdrop);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonBackdrop,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-backdrop',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['stopPropagation', 'tappable', 'visible']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBadge = class IonBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonBadge.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonBadge,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBadge.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonBadge,
  selector: "ion-badge",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBadge = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'mode']
})], IonBadge);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonBadge,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBreadcrumb = class IonBreadcrumb {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonBreadcrumb.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonBreadcrumb,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBreadcrumb.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonBreadcrumb,
  selector: "ion-breadcrumb",
  inputs: {
    active: "active",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    separator: "separator",
    target: "target"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBreadcrumb = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['active', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'separator', 'target']
})], IonBreadcrumb);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonBreadcrumb,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['active', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'separator', 'target']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBreadcrumbs = class IonBreadcrumbs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionCollapsedClick']);
  }
};
/** @nocollapse */
IonBreadcrumbs.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonBreadcrumbs,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBreadcrumbs.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonBreadcrumbs,
  selector: "ion-breadcrumbs",
  inputs: {
    color: "color",
    itemsAfterCollapse: "itemsAfterCollapse",
    itemsBeforeCollapse: "itemsBeforeCollapse",
    maxItems: "maxItems",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBreadcrumbs = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'itemsAfterCollapse', 'itemsBeforeCollapse', 'maxItems', 'mode']
})], IonBreadcrumbs);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonBreadcrumbs,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-breadcrumbs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'itemsAfterCollapse', 'itemsBeforeCollapse', 'maxItems', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonButton = class IonButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonButton,
  selector: "ion-button",
  inputs: {
    buttonType: "buttonType",
    color: "color",
    disabled: "disabled",
    download: "download",
    expand: "expand",
    fill: "fill",
    form: "form",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    shape: "shape",
    size: "size",
    strong: "strong",
    target: "target",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonButton = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['buttonType', 'color', 'disabled', 'download', 'expand', 'fill', 'form', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'size', 'strong', 'target', 'type']
})], IonButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['buttonType', 'color', 'disabled', 'download', 'expand', 'fill', 'form', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'size', 'strong', 'target', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonButtons = class IonButtons {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonButtons.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonButtons,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonButtons.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonButtons,
  selector: "ion-buttons",
  inputs: {
    collapse: "collapse"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonButtons = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['collapse']
})], IonButtons);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonButtons,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-buttons',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['collapse']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCard = class IonCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCard.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCard,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCard.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonCard,
  selector: "ion-card",
  inputs: {
    button: "button",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    target: "target",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCard = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['button', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'target', 'type']
})], IonCard);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCard,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['button', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'target', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCardContent = class IonCardContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardContent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCardContent,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCardContent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonCardContent,
  selector: "ion-card-content",
  inputs: {
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCardContent = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['mode']
})], IonCardContent);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCardContent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCardHeader = class IonCardHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardHeader.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCardHeader,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCardHeader.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonCardHeader,
  selector: "ion-card-header",
  inputs: {
    color: "color",
    mode: "mode",
    translucent: "translucent"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCardHeader = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'mode', 'translucent']
})], IonCardHeader);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCardHeader,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'mode', 'translucent']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCardSubtitle = class IonCardSubtitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardSubtitle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCardSubtitle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCardSubtitle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonCardSubtitle,
  selector: "ion-card-subtitle",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCardSubtitle = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'mode']
})], IonCardSubtitle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCardSubtitle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card-subtitle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCardTitle = class IonCardTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardTitle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCardTitle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCardTitle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonCardTitle,
  selector: "ion-card-title",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCardTitle = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'mode']
})], IonCardTitle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCardTitle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCheckbox = class IonCheckbox {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonCheckbox.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCheckbox,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCheckbox.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonCheckbox,
  selector: "ion-checkbox",
  inputs: {
    checked: "checked",
    color: "color",
    disabled: "disabled",
    indeterminate: "indeterminate",
    mode: "mode",
    name: "name",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCheckbox = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['checked', 'color', 'disabled', 'indeterminate', 'mode', 'name', 'value']
})], IonCheckbox);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCheckbox,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['checked', 'color', 'disabled', 'indeterminate', 'mode', 'name', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonChip = class IonChip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonChip.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonChip,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonChip.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonChip,
  selector: "ion-chip",
  inputs: {
    color: "color",
    disabled: "disabled",
    mode: "mode",
    outline: "outline"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonChip = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'disabled', 'mode', 'outline']
})], IonChip);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonChip,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-chip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'disabled', 'mode', 'outline']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCol = class IonCol {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCol.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCol,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCol.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonCol,
  selector: "ion-col",
  inputs: {
    offset: "offset",
    offsetLg: "offsetLg",
    offsetMd: "offsetMd",
    offsetSm: "offsetSm",
    offsetXl: "offsetXl",
    offsetXs: "offsetXs",
    pull: "pull",
    pullLg: "pullLg",
    pullMd: "pullMd",
    pullSm: "pullSm",
    pullXl: "pullXl",
    pullXs: "pullXs",
    push: "push",
    pushLg: "pushLg",
    pushMd: "pushMd",
    pushSm: "pushSm",
    pushXl: "pushXl",
    pushXs: "pushXs",
    size: "size",
    sizeLg: "sizeLg",
    sizeMd: "sizeMd",
    sizeSm: "sizeSm",
    sizeXl: "sizeXl",
    sizeXs: "sizeXs"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCol = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['offset', 'offsetLg', 'offsetMd', 'offsetSm', 'offsetXl', 'offsetXs', 'pull', 'pullLg', 'pullMd', 'pullSm', 'pullXl', 'pullXs', 'push', 'pushLg', 'pushMd', 'pushSm', 'pushXl', 'pushXs', 'size', 'sizeLg', 'sizeMd', 'sizeSm', 'sizeXl', 'sizeXs']
})], IonCol);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonCol,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-col',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['offset', 'offsetLg', 'offsetMd', 'offsetSm', 'offsetXl', 'offsetXs', 'pull', 'pullLg', 'pullMd', 'pullSm', 'pullXl', 'pullXs', 'push', 'pushLg', 'pushMd', 'pushSm', 'pushXl', 'pushXs', 'size', 'sizeLg', 'sizeMd', 'sizeSm', 'sizeXl', 'sizeXs']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonContent = class IonContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionScrollStart', 'ionScroll', 'ionScrollEnd']);
  }
};
/** @nocollapse */
IonContent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonContent,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonContent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonContent,
  selector: "ion-content",
  inputs: {
    color: "color",
    forceOverscroll: "forceOverscroll",
    fullscreen: "fullscreen",
    scrollEvents: "scrollEvents",
    scrollX: "scrollX",
    scrollY: "scrollY"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonContent = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'forceOverscroll', 'fullscreen', 'scrollEvents', 'scrollX', 'scrollY'],
  methods: ['getScrollElement', 'scrollToTop', 'scrollToBottom', 'scrollByPoint', 'scrollToPoint']
})], IonContent);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonContent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'forceOverscroll', 'fullscreen', 'scrollEvents', 'scrollX', 'scrollY']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonDatetime = class IonDatetime {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionCancel', 'ionChange', 'ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonDatetime.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonDatetime,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonDatetime.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonDatetime,
  selector: "ion-datetime",
  inputs: {
    cancelText: "cancelText",
    clearText: "clearText",
    color: "color",
    dayValues: "dayValues",
    disabled: "disabled",
    doneText: "doneText",
    firstDayOfWeek: "firstDayOfWeek",
    highlightedDates: "highlightedDates",
    hourCycle: "hourCycle",
    hourValues: "hourValues",
    isDateEnabled: "isDateEnabled",
    locale: "locale",
    max: "max",
    min: "min",
    minuteValues: "minuteValues",
    mode: "mode",
    monthValues: "monthValues",
    multiple: "multiple",
    name: "name",
    preferWheel: "preferWheel",
    presentation: "presentation",
    readonly: "readonly",
    showClearButton: "showClearButton",
    showDefaultButtons: "showDefaultButtons",
    showDefaultTimeLabel: "showDefaultTimeLabel",
    showDefaultTitle: "showDefaultTitle",
    size: "size",
    titleSelectedDatesFormatter: "titleSelectedDatesFormatter",
    value: "value",
    yearValues: "yearValues"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonDatetime = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['cancelText', 'clearText', 'color', 'dayValues', 'disabled', 'doneText', 'firstDayOfWeek', 'highlightedDates', 'hourCycle', 'hourValues', 'isDateEnabled', 'locale', 'max', 'min', 'minuteValues', 'mode', 'monthValues', 'multiple', 'name', 'preferWheel', 'presentation', 'readonly', 'showClearButton', 'showDefaultButtons', 'showDefaultTimeLabel', 'showDefaultTitle', 'size', 'titleSelectedDatesFormatter', 'value', 'yearValues'],
  methods: ['confirm', 'reset', 'cancel']
})], IonDatetime);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonDatetime,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-datetime',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['cancelText', 'clearText', 'color', 'dayValues', 'disabled', 'doneText', 'firstDayOfWeek', 'highlightedDates', 'hourCycle', 'hourValues', 'isDateEnabled', 'locale', 'max', 'min', 'minuteValues', 'mode', 'monthValues', 'multiple', 'name', 'preferWheel', 'presentation', 'readonly', 'showClearButton', 'showDefaultButtons', 'showDefaultTimeLabel', 'showDefaultTitle', 'size', 'titleSelectedDatesFormatter', 'value', 'yearValues']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonDatetimeButton = class IonDatetimeButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonDatetimeButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonDatetimeButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonDatetimeButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonDatetimeButton,
  selector: "ion-datetime-button",
  inputs: {
    color: "color",
    datetime: "datetime",
    disabled: "disabled",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonDatetimeButton = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'datetime', 'disabled', 'mode']
})], IonDatetimeButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonDatetimeButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-datetime-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'datetime', 'disabled', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonFab = class IonFab {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFab.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonFab,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonFab.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonFab,
  selector: "ion-fab",
  inputs: {
    activated: "activated",
    edge: "edge",
    horizontal: "horizontal",
    vertical: "vertical"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonFab = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['activated', 'edge', 'horizontal', 'vertical'],
  methods: ['close']
})], IonFab);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonFab,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-fab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['activated', 'edge', 'horizontal', 'vertical']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonFabButton = class IonFabButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonFabButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonFabButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonFabButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonFabButton,
  selector: "ion-fab-button",
  inputs: {
    activated: "activated",
    closeIcon: "closeIcon",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    show: "show",
    size: "size",
    target: "target",
    translucent: "translucent",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonFabButton = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['activated', 'closeIcon', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'show', 'size', 'target', 'translucent', 'type']
})], IonFabButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonFabButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-fab-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['activated', 'closeIcon', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'show', 'size', 'target', 'translucent', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonFabList = class IonFabList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFabList.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonFabList,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonFabList.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonFabList,
  selector: "ion-fab-list",
  inputs: {
    activated: "activated",
    side: "side"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonFabList = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['activated', 'side']
})], IonFabList);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonFabList,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-fab-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['activated', 'side']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonFooter = class IonFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFooter.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonFooter,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonFooter.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonFooter,
  selector: "ion-footer",
  inputs: {
    collapse: "collapse",
    mode: "mode",
    translucent: "translucent"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonFooter = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['collapse', 'mode', 'translucent']
})], IonFooter);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonFooter,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['collapse', 'mode', 'translucent']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonGrid = class IonGrid {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonGrid.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonGrid,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonGrid.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonGrid,
  selector: "ion-grid",
  inputs: {
    fixed: "fixed"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonGrid = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['fixed']
})], IonGrid);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonGrid,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-grid',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['fixed']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonHeader = class IonHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonHeader.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonHeader,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonHeader.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonHeader,
  selector: "ion-header",
  inputs: {
    collapse: "collapse",
    mode: "mode",
    translucent: "translucent"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonHeader = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['collapse', 'mode', 'translucent']
})], IonHeader);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonHeader,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['collapse', 'mode', 'translucent']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonIcon = class IonIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonIcon.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonIcon,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonIcon.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonIcon,
  selector: "ion-icon",
  inputs: {
    color: "color",
    flipRtl: "flipRtl",
    icon: "icon",
    ios: "ios",
    lazy: "lazy",
    md: "md",
    mode: "mode",
    name: "name",
    sanitize: "sanitize",
    size: "size",
    src: "src"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonIcon = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'flipRtl', 'icon', 'ios', 'lazy', 'md', 'mode', 'name', 'sanitize', 'size', 'src']
})], IonIcon);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonIcon,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'flipRtl', 'icon', 'ios', 'lazy', 'md', 'mode', 'name', 'sanitize', 'size', 'src']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonImg = class IonImg {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionImgWillLoad', 'ionImgDidLoad', 'ionError']);
  }
};
/** @nocollapse */
IonImg.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonImg,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonImg.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonImg,
  selector: "ion-img",
  inputs: {
    alt: "alt",
    src: "src"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonImg = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['alt', 'src']
})], IonImg);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonImg,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-img',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['alt', 'src']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonInfiniteScroll = class IonInfiniteScroll {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInfinite']);
  }
};
/** @nocollapse */
IonInfiniteScroll.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonInfiniteScroll,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonInfiniteScroll.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonInfiniteScroll,
  selector: "ion-infinite-scroll",
  inputs: {
    disabled: "disabled",
    position: "position",
    threshold: "threshold"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonInfiniteScroll = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['disabled', 'position', 'threshold'],
  methods: ['complete']
})], IonInfiniteScroll);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonInfiniteScroll,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-infinite-scroll',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'position', 'threshold']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonInfiniteScrollContent = class IonInfiniteScrollContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonInfiniteScrollContent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonInfiniteScrollContent,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonInfiniteScrollContent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonInfiniteScrollContent,
  selector: "ion-infinite-scroll-content",
  inputs: {
    loadingSpinner: "loadingSpinner",
    loadingText: "loadingText"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonInfiniteScrollContent = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['loadingSpinner', 'loadingText']
})], IonInfiniteScrollContent);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonInfiniteScrollContent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-infinite-scroll-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['loadingSpinner', 'loadingText']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonInput = class IonInput {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInput', 'ionChange', 'ionBlur', 'ionFocus']);
  }
};
/** @nocollapse */
IonInput.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonInput,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonInput.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonInput,
  selector: "ion-input",
  inputs: {
    accept: "accept",
    autocapitalize: "autocapitalize",
    autocomplete: "autocomplete",
    autocorrect: "autocorrect",
    autofocus: "autofocus",
    clearInput: "clearInput",
    clearOnEdit: "clearOnEdit",
    color: "color",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    inputmode: "inputmode",
    max: "max",
    maxlength: "maxlength",
    min: "min",
    minlength: "minlength",
    mode: "mode",
    multiple: "multiple",
    name: "name",
    pattern: "pattern",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    size: "size",
    spellcheck: "spellcheck",
    step: "step",
    type: "type",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonInput = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['accept', 'autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'clearInput', 'clearOnEdit', 'color', 'debounce', 'disabled', 'enterkeyhint', 'inputmode', 'max', 'maxlength', 'min', 'minlength', 'mode', 'multiple', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'size', 'spellcheck', 'step', 'type', 'value'],
  methods: ['setFocus', 'getInputElement']
})], IonInput);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonInput,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['accept', 'autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'clearInput', 'clearOnEdit', 'color', 'debounce', 'disabled', 'enterkeyhint', 'inputmode', 'max', 'maxlength', 'min', 'minlength', 'mode', 'multiple', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'size', 'spellcheck', 'step', 'type', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItem = class IonItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItem.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonItem,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItem.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonItem,
  selector: "ion-item",
  inputs: {
    button: "button",
    color: "color",
    counter: "counter",
    counterFormatter: "counterFormatter",
    detail: "detail",
    detailIcon: "detailIcon",
    disabled: "disabled",
    download: "download",
    fill: "fill",
    href: "href",
    lines: "lines",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    shape: "shape",
    target: "target",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItem = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['button', 'color', 'counter', 'counterFormatter', 'detail', 'detailIcon', 'disabled', 'download', 'fill', 'href', 'lines', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'target', 'type']
})], IonItem);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonItem,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['button', 'color', 'counter', 'counterFormatter', 'detail', 'detailIcon', 'disabled', 'download', 'fill', 'href', 'lines', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'target', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemDivider = class IonItemDivider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemDivider.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonItemDivider,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemDivider.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonItemDivider,
  selector: "ion-item-divider",
  inputs: {
    color: "color",
    mode: "mode",
    sticky: "sticky"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemDivider = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'mode', 'sticky']
})], IonItemDivider);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonItemDivider,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-divider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'mode', 'sticky']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemGroup = class IonItemGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemGroup.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonItemGroup,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemGroup.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonItemGroup,
  selector: "ion-item-group",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemGroup = __decorate([ProxyCmp({
  defineCustomElementFn: undefined
})], IonItemGroup);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonItemGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemOption = class IonItemOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemOption.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonItemOption,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemOption.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonItemOption,
  selector: "ion-item-option",
  inputs: {
    color: "color",
    disabled: "disabled",
    download: "download",
    expandable: "expandable",
    href: "href",
    mode: "mode",
    rel: "rel",
    target: "target",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemOption = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'disabled', 'download', 'expandable', 'href', 'mode', 'rel', 'target', 'type']
})], IonItemOption);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonItemOption,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'disabled', 'download', 'expandable', 'href', 'mode', 'rel', 'target', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemOptions = class IonItemOptions {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionSwipe']);
  }
};
/** @nocollapse */
IonItemOptions.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonItemOptions,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemOptions.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonItemOptions,
  selector: "ion-item-options",
  inputs: {
    side: "side"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemOptions = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['side']
})], IonItemOptions);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonItemOptions,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-options',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['side']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemSliding = class IonItemSliding {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionDrag']);
  }
};
/** @nocollapse */
IonItemSliding.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonItemSliding,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemSliding.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonItemSliding,
  selector: "ion-item-sliding",
  inputs: {
    disabled: "disabled"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemSliding = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['disabled'],
  methods: ['getOpenAmount', 'getSlidingRatio', 'open', 'close', 'closeOpened']
})], IonItemSliding);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonItemSliding,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-sliding',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonLabel = class IonLabel {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonLabel.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonLabel,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonLabel.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonLabel,
  selector: "ion-label",
  inputs: {
    color: "color",
    mode: "mode",
    position: "position"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonLabel = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'mode', 'position']
})], IonLabel);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonLabel,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-label',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'mode', 'position']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonList = class IonList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonList.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonList,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonList.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonList,
  selector: "ion-list",
  inputs: {
    inset: "inset",
    lines: "lines",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonList = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['inset', 'lines', 'mode'],
  methods: ['closeSlidingItems']
})], IonList);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonList,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['inset', 'lines', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonListHeader = class IonListHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonListHeader.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonListHeader,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonListHeader.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonListHeader,
  selector: "ion-list-header",
  inputs: {
    color: "color",
    lines: "lines",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonListHeader = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'lines', 'mode']
})], IonListHeader);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonListHeader,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-list-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'lines', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonMenu = class IonMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionWillOpen', 'ionWillClose', 'ionDidOpen', 'ionDidClose']);
  }
};
/** @nocollapse */
IonMenu.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonMenu,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonMenu.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonMenu,
  selector: "ion-menu",
  inputs: {
    contentId: "contentId",
    disabled: "disabled",
    maxEdgeStart: "maxEdgeStart",
    menuId: "menuId",
    side: "side",
    swipeGesture: "swipeGesture",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonMenu = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['contentId', 'disabled', 'maxEdgeStart', 'menuId', 'side', 'swipeGesture', 'type'],
  methods: ['isOpen', 'isActive', 'open', 'close', 'toggle', 'setOpen']
})], IonMenu);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonMenu,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['contentId', 'disabled', 'maxEdgeStart', 'menuId', 'side', 'swipeGesture', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonMenuButton = class IonMenuButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonMenuButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonMenuButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonMenuButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonMenuButton,
  selector: "ion-menu-button",
  inputs: {
    autoHide: "autoHide",
    color: "color",
    disabled: "disabled",
    menu: "menu",
    mode: "mode",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonMenuButton = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['autoHide', 'color', 'disabled', 'menu', 'mode', 'type']
})], IonMenuButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonMenuButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-menu-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['autoHide', 'color', 'disabled', 'menu', 'mode', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonMenuToggle = class IonMenuToggle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonMenuToggle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonMenuToggle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonMenuToggle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonMenuToggle,
  selector: "ion-menu-toggle",
  inputs: {
    autoHide: "autoHide",
    menu: "menu"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonMenuToggle = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['autoHide', 'menu']
})], IonMenuToggle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonMenuToggle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-menu-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['autoHide', 'menu']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonNav = class IonNav {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionNavWillChange', 'ionNavDidChange']);
  }
};
/** @nocollapse */
IonNav.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonNav,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonNav.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonNav,
  selector: "ion-nav",
  inputs: {
    animated: "animated",
    animation: "animation",
    root: "root",
    rootParams: "rootParams",
    swipeGesture: "swipeGesture"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonNav = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['animated', 'animation', 'root', 'rootParams', 'swipeGesture'],
  methods: ['push', 'insert', 'insertPages', 'pop', 'popTo', 'popToRoot', 'removeIndex', 'setRoot', 'setPages', 'getActive', 'getByIndex', 'canGoBack', 'getPrevious']
})], IonNav);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonNav,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-nav',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['animated', 'animation', 'root', 'rootParams', 'swipeGesture']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonNavLink = class IonNavLink {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonNavLink.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonNavLink,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonNavLink.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonNavLink,
  selector: "ion-nav-link",
  inputs: {
    component: "component",
    componentProps: "componentProps",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonNavLink = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['component', 'componentProps', 'routerAnimation', 'routerDirection']
})], IonNavLink);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonNavLink,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-nav-link',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['component', 'componentProps', 'routerAnimation', 'routerDirection']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonNote = class IonNote {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonNote.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonNote,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonNote.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonNote,
  selector: "ion-note",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonNote = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'mode']
})], IonNote);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonNote,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-note',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonProgressBar = class IonProgressBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonProgressBar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonProgressBar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonProgressBar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonProgressBar,
  selector: "ion-progress-bar",
  inputs: {
    buffer: "buffer",
    color: "color",
    mode: "mode",
    reversed: "reversed",
    type: "type",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonProgressBar = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['buffer', 'color', 'mode', 'reversed', 'type', 'value']
})], IonProgressBar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonProgressBar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-progress-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['buffer', 'color', 'mode', 'reversed', 'type', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRadio = class IonRadio {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonRadio.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRadio,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRadio.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonRadio,
  selector: "ion-radio",
  inputs: {
    color: "color",
    disabled: "disabled",
    mode: "mode",
    name: "name",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRadio = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'disabled', 'mode', 'name', 'value']
})], IonRadio);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRadio,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-radio',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'disabled', 'mode', 'name', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRadioGroup = class IonRadioGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
};
/** @nocollapse */
IonRadioGroup.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRadioGroup,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRadioGroup.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonRadioGroup,
  selector: "ion-radio-group",
  inputs: {
    allowEmptySelection: "allowEmptySelection",
    name: "name",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRadioGroup = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['allowEmptySelection', 'name', 'value']
})], IonRadioGroup);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRadioGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-radio-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['allowEmptySelection', 'name', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRange = class IonRange {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionFocus', 'ionBlur', 'ionKnobMoveStart', 'ionKnobMoveEnd']);
  }
};
/** @nocollapse */
IonRange.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRange,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRange.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonRange,
  selector: "ion-range",
  inputs: {
    activeBarStart: "activeBarStart",
    color: "color",
    debounce: "debounce",
    disabled: "disabled",
    dualKnobs: "dualKnobs",
    max: "max",
    min: "min",
    mode: "mode",
    name: "name",
    pin: "pin",
    pinFormatter: "pinFormatter",
    snaps: "snaps",
    step: "step",
    ticks: "ticks",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRange = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['activeBarStart', 'color', 'debounce', 'disabled', 'dualKnobs', 'max', 'min', 'mode', 'name', 'pin', 'pinFormatter', 'snaps', 'step', 'ticks', 'value']
})], IonRange);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRange,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-range',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['activeBarStart', 'color', 'debounce', 'disabled', 'dualKnobs', 'max', 'min', 'mode', 'name', 'pin', 'pinFormatter', 'snaps', 'step', 'ticks', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRefresher = class IonRefresher {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionRefresh', 'ionPull', 'ionStart']);
  }
};
/** @nocollapse */
IonRefresher.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRefresher,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRefresher.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonRefresher,
  selector: "ion-refresher",
  inputs: {
    closeDuration: "closeDuration",
    disabled: "disabled",
    pullFactor: "pullFactor",
    pullMax: "pullMax",
    pullMin: "pullMin",
    snapbackDuration: "snapbackDuration"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRefresher = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['closeDuration', 'disabled', 'pullFactor', 'pullMax', 'pullMin', 'snapbackDuration'],
  methods: ['complete', 'cancel', 'getProgress']
})], IonRefresher);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRefresher,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-refresher',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['closeDuration', 'disabled', 'pullFactor', 'pullMax', 'pullMin', 'snapbackDuration']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRefresherContent = class IonRefresherContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRefresherContent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRefresherContent,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRefresherContent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonRefresherContent,
  selector: "ion-refresher-content",
  inputs: {
    pullingIcon: "pullingIcon",
    pullingText: "pullingText",
    refreshingSpinner: "refreshingSpinner",
    refreshingText: "refreshingText"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRefresherContent = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['pullingIcon', 'pullingText', 'refreshingSpinner', 'refreshingText']
})], IonRefresherContent);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRefresherContent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-refresher-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['pullingIcon', 'pullingText', 'refreshingSpinner', 'refreshingText']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonReorder = class IonReorder {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonReorder.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonReorder,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonReorder.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonReorder,
  selector: "ion-reorder",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonReorder = __decorate([ProxyCmp({
  defineCustomElementFn: undefined
})], IonReorder);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonReorder,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-reorder',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonReorderGroup = class IonReorderGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionItemReorder']);
  }
};
/** @nocollapse */
IonReorderGroup.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonReorderGroup,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonReorderGroup.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonReorderGroup,
  selector: "ion-reorder-group",
  inputs: {
    disabled: "disabled"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonReorderGroup = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['disabled'],
  methods: ['complete']
})], IonReorderGroup);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonReorderGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-reorder-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRippleEffect = class IonRippleEffect {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRippleEffect.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRippleEffect,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRippleEffect.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonRippleEffect,
  selector: "ion-ripple-effect",
  inputs: {
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRippleEffect = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['type'],
  methods: ['addRipple']
})], IonRippleEffect);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRippleEffect,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-ripple-effect',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRow = class IonRow {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRow.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRow,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRow.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonRow,
  selector: "ion-row",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRow = __decorate([ProxyCmp({
  defineCustomElementFn: undefined
})], IonRow);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRow,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-row',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSearchbar = class IonSearchbar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInput', 'ionChange', 'ionCancel', 'ionClear', 'ionBlur', 'ionFocus']);
  }
};
/** @nocollapse */
IonSearchbar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSearchbar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSearchbar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonSearchbar,
  selector: "ion-searchbar",
  inputs: {
    animated: "animated",
    autocomplete: "autocomplete",
    autocorrect: "autocorrect",
    cancelButtonIcon: "cancelButtonIcon",
    cancelButtonText: "cancelButtonText",
    clearIcon: "clearIcon",
    color: "color",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    inputmode: "inputmode",
    mode: "mode",
    placeholder: "placeholder",
    searchIcon: "searchIcon",
    showCancelButton: "showCancelButton",
    showClearButton: "showClearButton",
    spellcheck: "spellcheck",
    type: "type",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSearchbar = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['animated', 'autocomplete', 'autocorrect', 'cancelButtonIcon', 'cancelButtonText', 'clearIcon', 'color', 'debounce', 'disabled', 'enterkeyhint', 'inputmode', 'mode', 'placeholder', 'searchIcon', 'showCancelButton', 'showClearButton', 'spellcheck', 'type', 'value'],
  methods: ['setFocus', 'getInputElement']
})], IonSearchbar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSearchbar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-searchbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['animated', 'autocomplete', 'autocorrect', 'cancelButtonIcon', 'cancelButtonText', 'clearIcon', 'color', 'debounce', 'disabled', 'enterkeyhint', 'inputmode', 'mode', 'placeholder', 'searchIcon', 'showCancelButton', 'showClearButton', 'spellcheck', 'type', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSegment = class IonSegment {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
};
/** @nocollapse */
IonSegment.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSegment,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSegment.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonSegment,
  selector: "ion-segment",
  inputs: {
    color: "color",
    disabled: "disabled",
    mode: "mode",
    scrollable: "scrollable",
    selectOnFocus: "selectOnFocus",
    swipeGesture: "swipeGesture",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSegment = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'disabled', 'mode', 'scrollable', 'selectOnFocus', 'swipeGesture', 'value']
})], IonSegment);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSegment,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-segment',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'disabled', 'mode', 'scrollable', 'selectOnFocus', 'swipeGesture', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSegmentButton = class IonSegmentButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSegmentButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSegmentButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSegmentButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonSegmentButton,
  selector: "ion-segment-button",
  inputs: {
    disabled: "disabled",
    layout: "layout",
    mode: "mode",
    type: "type",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSegmentButton = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['disabled', 'layout', 'mode', 'type', 'value']
})], IonSegmentButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSegmentButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-segment-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'layout', 'mode', 'type', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSelect = class IonSelect {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionCancel', 'ionDismiss', 'ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonSelect.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSelect,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSelect.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonSelect,
  selector: "ion-select",
  inputs: {
    cancelText: "cancelText",
    compareWith: "compareWith",
    disabled: "disabled",
    interface: "interface",
    interfaceOptions: "interfaceOptions",
    mode: "mode",
    multiple: "multiple",
    name: "name",
    okText: "okText",
    placeholder: "placeholder",
    selectedText: "selectedText",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSelect = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['cancelText', 'compareWith', 'disabled', 'interface', 'interfaceOptions', 'mode', 'multiple', 'name', 'okText', 'placeholder', 'selectedText', 'value'],
  methods: ['open']
})], IonSelect);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSelect,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-select',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['cancelText', 'compareWith', 'disabled', 'interface', 'interfaceOptions', 'mode', 'multiple', 'name', 'okText', 'placeholder', 'selectedText', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSelectOption = class IonSelectOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSelectOption.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSelectOption,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSelectOption.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonSelectOption,
  selector: "ion-select-option",
  inputs: {
    disabled: "disabled",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSelectOption = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['disabled', 'value']
})], IonSelectOption);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSelectOption,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-select-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSkeletonText = class IonSkeletonText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSkeletonText.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSkeletonText,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSkeletonText.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonSkeletonText,
  selector: "ion-skeleton-text",
  inputs: {
    animated: "animated"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSkeletonText = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['animated']
})], IonSkeletonText);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSkeletonText,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-skeleton-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['animated']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSlide = class IonSlide {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSlide.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSlide,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSlide.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonSlide,
  selector: "ion-slide",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSlide = __decorate([ProxyCmp({
  defineCustomElementFn: undefined
})], IonSlide);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSlide,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-slide',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSlides = class IonSlides {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionSlidesDidLoad', 'ionSlideTap', 'ionSlideDoubleTap', 'ionSlideWillChange', 'ionSlideDidChange', 'ionSlideNextStart', 'ionSlidePrevStart', 'ionSlideNextEnd', 'ionSlidePrevEnd', 'ionSlideTransitionStart', 'ionSlideTransitionEnd', 'ionSlideDrag', 'ionSlideReachStart', 'ionSlideReachEnd', 'ionSlideTouchStart', 'ionSlideTouchEnd']);
  }
};
/** @nocollapse */
IonSlides.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSlides,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSlides.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonSlides,
  selector: "ion-slides",
  inputs: {
    mode: "mode",
    options: "options",
    pager: "pager",
    scrollbar: "scrollbar"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSlides = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['mode', 'options', 'pager', 'scrollbar'],
  methods: ['update', 'updateAutoHeight', 'slideTo', 'slideNext', 'slidePrev', 'getActiveIndex', 'getPreviousIndex', 'length', 'isEnd', 'isBeginning', 'startAutoplay', 'stopAutoplay', 'lockSwipeToNext', 'lockSwipeToPrev', 'lockSwipes', 'getSwiper']
})], IonSlides);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSlides,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-slides',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['mode', 'options', 'pager', 'scrollbar']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSpinner = class IonSpinner {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSpinner.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSpinner,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSpinner.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonSpinner,
  selector: "ion-spinner",
  inputs: {
    color: "color",
    duration: "duration",
    name: "name",
    paused: "paused"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSpinner = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'duration', 'name', 'paused']
})], IonSpinner);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSpinner,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-spinner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'duration', 'name', 'paused']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSplitPane = class IonSplitPane {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionSplitPaneVisible']);
  }
};
/** @nocollapse */
IonSplitPane.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSplitPane,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSplitPane.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonSplitPane,
  selector: "ion-split-pane",
  inputs: {
    contentId: "contentId",
    disabled: "disabled",
    when: "when"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSplitPane = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['contentId', 'disabled', 'when']
})], IonSplitPane);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonSplitPane,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-split-pane',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['contentId', 'disabled', 'when']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonTabBar = class IonTabBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTabBar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonTabBar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTabBar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonTabBar,
  selector: "ion-tab-bar",
  inputs: {
    color: "color",
    mode: "mode",
    selectedTab: "selectedTab",
    translucent: "translucent"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonTabBar = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'mode', 'selectedTab', 'translucent']
})], IonTabBar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonTabBar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-tab-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'mode', 'selectedTab', 'translucent']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonTabButton = class IonTabButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTabButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonTabButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTabButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonTabButton,
  selector: "ion-tab-button",
  inputs: {
    disabled: "disabled",
    download: "download",
    href: "href",
    layout: "layout",
    mode: "mode",
    rel: "rel",
    selected: "selected",
    tab: "tab",
    target: "target"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonTabButton = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['disabled', 'download', 'href', 'layout', 'mode', 'rel', 'selected', 'tab', 'target']
})], IonTabButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonTabButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-tab-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'download', 'href', 'layout', 'mode', 'rel', 'selected', 'tab', 'target']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonText = class IonText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonText.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonText,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonText.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonText,
  selector: "ion-text",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonText = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'mode']
})], IonText);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonText,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonTextarea = class IonTextarea {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionInput', 'ionBlur', 'ionFocus']);
  }
};
/** @nocollapse */
IonTextarea.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonTextarea,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTextarea.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonTextarea,
  selector: "ion-textarea",
  inputs: {
    autoGrow: "autoGrow",
    autocapitalize: "autocapitalize",
    autofocus: "autofocus",
    clearOnEdit: "clearOnEdit",
    color: "color",
    cols: "cols",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    inputmode: "inputmode",
    maxlength: "maxlength",
    minlength: "minlength",
    mode: "mode",
    name: "name",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    rows: "rows",
    spellcheck: "spellcheck",
    value: "value",
    wrap: "wrap"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonTextarea = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['autoGrow', 'autocapitalize', 'autofocus', 'clearOnEdit', 'color', 'cols', 'debounce', 'disabled', 'enterkeyhint', 'inputmode', 'maxlength', 'minlength', 'mode', 'name', 'placeholder', 'readonly', 'required', 'rows', 'spellcheck', 'value', 'wrap'],
  methods: ['setFocus', 'getInputElement']
})], IonTextarea);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonTextarea,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-textarea',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['autoGrow', 'autocapitalize', 'autofocus', 'clearOnEdit', 'color', 'cols', 'debounce', 'disabled', 'enterkeyhint', 'inputmode', 'maxlength', 'minlength', 'mode', 'name', 'placeholder', 'readonly', 'required', 'rows', 'spellcheck', 'value', 'wrap']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonThumbnail = class IonThumbnail {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonThumbnail.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonThumbnail,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonThumbnail.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonThumbnail,
  selector: "ion-thumbnail",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonThumbnail = __decorate([ProxyCmp({
  defineCustomElementFn: undefined
})], IonThumbnail);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonThumbnail,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-thumbnail',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonTitle = class IonTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTitle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonTitle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTitle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonTitle,
  selector: "ion-title",
  inputs: {
    color: "color",
    size: "size"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonTitle = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'size']
})], IonTitle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonTitle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'size']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonToggle = class IonToggle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonToggle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonToggle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonToggle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonToggle,
  selector: "ion-toggle",
  inputs: {
    checked: "checked",
    color: "color",
    disabled: "disabled",
    enableOnOffLabels: "enableOnOffLabels",
    mode: "mode",
    name: "name",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonToggle = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['checked', 'color', 'disabled', 'enableOnOffLabels', 'mode', 'name', 'value']
})], IonToggle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonToggle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['checked', 'color', 'disabled', 'enableOnOffLabels', 'mode', 'name', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonToolbar = class IonToolbar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonToolbar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonToolbar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonToolbar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonToolbar,
  selector: "ion-toolbar",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonToolbar = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['color', 'mode']
})], IonToolbar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonToolbar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-toolbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['color', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});

/**
 * @description
 * NavParams are an object that exists on a page and can contain data for that particular view.
 * Similar to how data was pass to a view in V1 with `$stateParams`, NavParams offer a much more flexible
 * option with a simple `get` method.
 *
 * @usage
 * ```ts
 * import { NavParams } from '@ionic/angular';
 *
 * export class MyClass{
 *
 *  constructor(navParams: NavParams){
 *    // userParams is an object we have in our nav-parameters
 *    navParams.get('userParams');
 *  }
 *
 * }
 * ```
 */
class NavParams {
  constructor(data = {}) {
    this.data = data;
  }
  /**
   * Get the value of a nav-parameter for the current view
   *
   * ```ts
   * import { NavParams } from 'ionic-angular';
   *
   * export class MyClass{
   *  constructor(public navParams: NavParams){
   *    // userParams is an object we have in our nav-parameters
   *    this.navParams.get('userParams');
   *  }
   * }
   * ```
   *
   * @param param Which param you want to look up
   */
  get(param) {
    return this.data[param];
  }
}

// TODO(FW-2827): types
class AngularDelegate {
  constructor(zone, appRef) {
    this.zone = zone;
    this.appRef = appRef;
  }
  create(resolverOrInjector, injector, location, elementReferenceKey) {
    return new AngularFrameworkDelegate(resolverOrInjector, injector, location, this.appRef, this.zone, elementReferenceKey);
  }
}
/** @nocollapse */
AngularDelegate.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: AngularDelegate,
  deps: [{
    token: i0.NgZone
  }, {
    token: i0.ApplicationRef
  }],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
AngularDelegate.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: AngularDelegate
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: AngularDelegate,
  decorators: [{
    type: Injectable
  }],
  ctorParameters: function () {
    return [{
      type: i0.NgZone
    }, {
      type: i0.ApplicationRef
    }];
  }
});
class AngularFrameworkDelegate {
  constructor(resolverOrInjector, injector, location, appRef, zone, elementReferenceKey) {
    this.resolverOrInjector = resolverOrInjector;
    this.injector = injector;
    this.location = location;
    this.appRef = appRef;
    this.zone = zone;
    this.elementReferenceKey = elementReferenceKey;
    this.elRefMap = new WeakMap();
    this.elEventsMap = new WeakMap();
  }
  attachViewToDom(container, component, params, cssClasses) {
    return this.zone.run(() => {
      return new Promise(resolve => {
        const componentProps = Object.assign({}, params);
        /**
         * Ionic Angular passes a reference to a modal
         * or popover that can be accessed using a
         * variable in the overlay component. If
         * elementReferenceKey is defined, then we should
         * pass a reference to the component using
         * elementReferenceKey as the key.
         */
        if (this.elementReferenceKey !== undefined) {
          componentProps[this.elementReferenceKey] = container;
        }
        const el = attachView(this.zone, this.resolverOrInjector, this.injector, this.location, this.appRef, this.elRefMap, this.elEventsMap, container, component, componentProps, cssClasses);
        resolve(el);
      });
    });
  }
  removeViewFromDom(_container, component) {
    return this.zone.run(() => {
      return new Promise(resolve => {
        const componentRef = this.elRefMap.get(component);
        if (componentRef) {
          componentRef.destroy();
          this.elRefMap.delete(component);
          const unbindEvents = this.elEventsMap.get(component);
          if (unbindEvents) {
            unbindEvents();
            this.elEventsMap.delete(component);
          }
        }
        resolve();
      });
    });
  }
}
const attachView = (zone, resolverOrInjector, injector, location, appRef, elRefMap, elEventsMap, container, component, params, cssClasses) => {
  let componentRef;
  const childInjector = Injector.create({
    providers: getProviders(params),
    parent: injector
  });
  if (resolverOrInjector && isComponentFactoryResolver(resolverOrInjector)) {
    // Angular 13 and lower
    const factory = resolverOrInjector.resolveComponentFactory(component);
    componentRef = location ? location.createComponent(factory, location.length, childInjector) : factory.create(childInjector);
  } else if (location) {
    // Angular 14
    const environmentInjector = resolverOrInjector;
    componentRef = location.createComponent(component, {
      index: location.indexOf,
      injector: childInjector,
      environmentInjector
    });
  } else {
    return null;
  }
  const instance = componentRef.instance;
  const hostElement = componentRef.location.nativeElement;
  if (params) {
    Object.assign(instance, params);
  }
  if (cssClasses) {
    for (const clazz of cssClasses) {
      hostElement.classList.add(clazz);
    }
  }
  const unbindEvents = bindLifecycleEvents(zone, instance, hostElement);
  container.appendChild(hostElement);
  if (!location) {
    appRef.attachView(componentRef.hostView);
  }
  componentRef.changeDetectorRef.reattach();
  elRefMap.set(hostElement, componentRef);
  elEventsMap.set(hostElement, unbindEvents);
  return hostElement;
};
const LIFECYCLES = [LIFECYCLE_WILL_ENTER, LIFECYCLE_DID_ENTER, LIFECYCLE_WILL_LEAVE, LIFECYCLE_DID_LEAVE, LIFECYCLE_WILL_UNLOAD];
const bindLifecycleEvents = (zone, instance, element) => {
  return zone.run(() => {
    const unregisters = LIFECYCLES.filter(eventName => typeof instance[eventName] === 'function').map(eventName => {
      const handler = ev => instance[eventName](ev.detail);
      element.addEventListener(eventName, handler);
      return () => element.removeEventListener(eventName, handler);
    });
    return () => unregisters.forEach(fn => fn());
  });
};
const NavParamsToken = new InjectionToken('NavParamsToken');
const getProviders = params => {
  return [{
    provide: NavParamsToken,
    useValue: params
  }, {
    provide: NavParams,
    useFactory: provideNavParamsInjectable,
    deps: [NavParamsToken]
  }];
};
const provideNavParamsInjectable = params => {
  return new NavParams(params);
};
const insertView = (views, view, direction) => {
  if (direction === 'root') {
    return setRoot(views, view);
  } else if (direction === 'forward') {
    return setForward(views, view);
  } else {
    return setBack(views, view);
  }
};
const setRoot = (views, view) => {
  views = views.filter(v => v.stackId !== view.stackId);
  views.push(view);
  return views;
};
const setForward = (views, view) => {
  const index = views.indexOf(view);
  if (index >= 0) {
    views = views.filter(v => v.stackId !== view.stackId || v.id <= view.id);
  } else {
    views.push(view);
  }
  return views;
};
const setBack = (views, view) => {
  const index = views.indexOf(view);
  if (index >= 0) {
    return views.filter(v => v.stackId !== view.stackId || v.id <= view.id);
  } else {
    return setRoot(views, view);
  }
};
const getUrl = (router, activatedRoute) => {
  const urlTree = router.createUrlTree(['.'], {
    relativeTo: activatedRoute
  });
  return router.serializeUrl(urlTree);
};
const isTabSwitch = (enteringView, leavingView) => {
  if (!leavingView) {
    return true;
  }
  return enteringView.stackId !== leavingView.stackId;
};
const computeStackId = (prefixUrl, url) => {
  if (!prefixUrl) {
    return undefined;
  }
  const segments = toSegments(url);
  for (let i = 0; i < segments.length; i++) {
    if (i >= prefixUrl.length) {
      return segments[i];
    }
    if (segments[i] !== prefixUrl[i]) {
      return undefined;
    }
  }
  return undefined;
};
const toSegments = path => {
  return path.split('/').map(s => s.trim()).filter(s => s !== '');
};
const destroyView = view => {
  if (view) {
    view.ref.destroy();
    view.unlistenEvents();
  }
};

// TODO(FW-2827): types
class StackController {
  constructor(tabsPrefix, containerEl, router, navCtrl, zone, location) {
    this.containerEl = containerEl;
    this.router = router;
    this.navCtrl = navCtrl;
    this.zone = zone;
    this.location = location;
    this.views = [];
    this.skipTransition = false;
    this.nextId = 0;
    this.tabsPrefix = tabsPrefix !== undefined ? toSegments(tabsPrefix) : undefined;
  }
  createView(ref, activatedRoute) {
    var _a;
    const url = getUrl(this.router, activatedRoute);
    const element = (_a = ref === null || ref === void 0 ? void 0 : ref.location) === null || _a === void 0 ? void 0 : _a.nativeElement;
    const unlistenEvents = bindLifecycleEvents(this.zone, ref.instance, element);
    return {
      id: this.nextId++,
      stackId: computeStackId(this.tabsPrefix, url),
      unlistenEvents,
      element,
      ref,
      url
    };
  }
  getExistingView(activatedRoute) {
    const activatedUrlKey = getUrl(this.router, activatedRoute);
    const view = this.views.find(vw => vw.url === activatedUrlKey);
    if (view) {
      view.ref.changeDetectorRef.reattach();
    }
    return view;
  }
  setActive(enteringView) {
    var _a, _b;
    const consumeResult = this.navCtrl.consumeTransition();
    let {
      direction,
      animation,
      animationBuilder
    } = consumeResult;
    const leavingView = this.activeView;
    const tabSwitch = isTabSwitch(enteringView, leavingView);
    if (tabSwitch) {
      direction = 'back';
      animation = undefined;
    }
    const viewsSnapshot = this.views.slice();
    let currentNavigation;
    const router = this.router;
    // Angular >= 7.2.0
    if (router.getCurrentNavigation) {
      currentNavigation = router.getCurrentNavigation();
      // Angular < 7.2.0
    } else if ((_a = router.navigations) === null || _a === void 0 ? void 0 : _a.value) {
      currentNavigation = router.navigations.value;
    }
    /**
     * If the navigation action
     * sets `replaceUrl: true`
     * then we need to make sure
     * we remove the last item
     * from our views stack
     */
    if ((_b = currentNavigation === null || currentNavigation === void 0 ? void 0 : currentNavigation.extras) === null || _b === void 0 ? void 0 : _b.replaceUrl) {
      if (this.views.length > 0) {
        this.views.splice(-1, 1);
      }
    }
    const reused = this.views.includes(enteringView);
    const views = this.insertView(enteringView, direction);
    // Trigger change detection before transition starts
    // This will call ngOnInit() the first time too, just after the view
    // was attached to the dom, but BEFORE the transition starts
    if (!reused) {
      enteringView.ref.changeDetectorRef.detectChanges();
    }
    /**
     * If we are going back from a page that
     * was presented using a custom animation
     * we should default to using that
     * unless the developer explicitly
     * provided another animation.
     */
    const customAnimation = enteringView.animationBuilder;
    if (animationBuilder === undefined && direction === 'back' && !tabSwitch && customAnimation !== undefined) {
      animationBuilder = customAnimation;
    }
    /**
     * Save any custom animation so that navigating
     * back will use this custom animation by default.
     */
    if (leavingView) {
      leavingView.animationBuilder = animationBuilder;
    }
    // Wait until previous transitions finish
    return this.zone.runOutsideAngular(() => {
      return this.wait(() => {
        // disconnect leaving page from change detection to
        // reduce jank during the page transition
        if (leavingView) {
          leavingView.ref.changeDetectorRef.detach();
        }
        // In case the enteringView is the same as the leavingPage we need to reattach()
        enteringView.ref.changeDetectorRef.reattach();
        return this.transition(enteringView, leavingView, animation, this.canGoBack(1), false, animationBuilder).then(() => cleanupAsync(enteringView, views, viewsSnapshot, this.location, this.zone)).then(() => ({
          enteringView,
          direction,
          animation,
          tabSwitch
        }));
      });
    });
  }
  canGoBack(deep, stackId = this.getActiveStackId()) {
    return this.getStack(stackId).length > deep;
  }
  pop(deep, stackId = this.getActiveStackId()) {
    return this.zone.run(() => {
      var _a, _b;
      const views = this.getStack(stackId);
      if (views.length <= deep) {
        return Promise.resolve(false);
      }
      const view = views[views.length - deep - 1];
      let url = view.url;
      const viewSavedData = view.savedData;
      if (viewSavedData) {
        const primaryOutlet = viewSavedData.get('primary');
        if ((_b = (_a = primaryOutlet === null || primaryOutlet === void 0 ? void 0 : primaryOutlet.route) === null || _a === void 0 ? void 0 : _a._routerState) === null || _b === void 0 ? void 0 : _b.snapshot.url) {
          url = primaryOutlet.route._routerState.snapshot.url;
        }
      }
      const {
        animationBuilder
      } = this.navCtrl.consumeTransition();
      return this.navCtrl.navigateBack(url, Object.assign(Object.assign({}, view.savedExtras), {
        animation: animationBuilder
      })).then(() => true);
    });
  }
  startBackTransition() {
    const leavingView = this.activeView;
    if (leavingView) {
      const views = this.getStack(leavingView.stackId);
      const enteringView = views[views.length - 2];
      const customAnimation = enteringView.animationBuilder;
      return this.wait(() => {
        return this.transition(enteringView,
        // entering view
        leavingView,
        // leaving view
        'back', this.canGoBack(2), true, customAnimation);
      });
    }
    return Promise.resolve();
  }
  endBackTransition(shouldComplete) {
    if (shouldComplete) {
      this.skipTransition = true;
      this.pop(1);
    } else if (this.activeView) {
      cleanup(this.activeView, this.views, this.views, this.location, this.zone);
    }
  }
  getLastUrl(stackId) {
    const views = this.getStack(stackId);
    return views.length > 0 ? views[views.length - 1] : undefined;
  }
  /**
   * @internal
   */
  getRootUrl(stackId) {
    const views = this.getStack(stackId);
    return views.length > 0 ? views[0] : undefined;
  }
  getActiveStackId() {
    return this.activeView ? this.activeView.stackId : undefined;
  }
  hasRunningTask() {
    return this.runningTask !== undefined;
  }
  destroy() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.containerEl = undefined;
    this.views.forEach(destroyView);
    this.activeView = undefined;
    this.views = [];
  }
  getStack(stackId) {
    return this.views.filter(v => v.stackId === stackId);
  }
  insertView(enteringView, direction) {
    this.activeView = enteringView;
    this.views = insertView(this.views, enteringView, direction);
    return this.views.slice();
  }
  transition(enteringView, leavingView, direction, showGoBack, progressAnimation, animationBuilder) {
    if (this.skipTransition) {
      this.skipTransition = false;
      return Promise.resolve(false);
    }
    if (leavingView === enteringView) {
      return Promise.resolve(false);
    }
    const enteringEl = enteringView ? enteringView.element : undefined;
    const leavingEl = leavingView ? leavingView.element : undefined;
    const containerEl = this.containerEl;
    if (enteringEl && enteringEl !== leavingEl) {
      enteringEl.classList.add('ion-page');
      enteringEl.classList.add('ion-page-invisible');
      if (enteringEl.parentElement !== containerEl) {
        containerEl.appendChild(enteringEl);
      }
      if (containerEl.commit) {
        return containerEl.commit(enteringEl, leavingEl, {
          deepWait: true,
          duration: direction === undefined ? 0 : undefined,
          direction,
          showGoBack,
          progressAnimation,
          animationBuilder
        });
      }
    }
    return Promise.resolve(false);
  }
  wait(task) {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.runningTask !== undefined) {
        yield this.runningTask;
        this.runningTask = undefined;
      }
      const promise = this.runningTask = task();
      promise.finally(() => this.runningTask = undefined);
      return promise;
    });
  }
}
const cleanupAsync = (activeRoute, views, viewsSnapshot, location, zone) => {
  if (typeof requestAnimationFrame === 'function') {
    return new Promise(resolve => {
      requestAnimationFrame(() => {
        cleanup(activeRoute, views, viewsSnapshot, location, zone);
        resolve();
      });
    });
  }
  return Promise.resolve();
};
const cleanup = (activeRoute, views, viewsSnapshot, location, zone) => {
  /**
   * Re-enter the Angular zone when destroying page components. This will allow
   * lifecycle events (`ngOnDestroy`) to be run inside the Angular zone.
   */
  zone.run(() => viewsSnapshot.filter(view => !views.includes(view)).forEach(destroyView));
  views.forEach(view => {
    /**
     * In the event that a user navigated multiple
     * times in rapid succession, we want to make sure
     * we don't pre-emptively detach a view while
     * it is in mid-transition.
     *
     * In this instance we also do not care about query
     * params or fragments as it will be the same view regardless
     */
    const locationWithoutParams = location.path().split('?')[0];
    const locationWithoutFragment = locationWithoutParams.split('#')[0];
    if (view !== activeRoute && view.url !== locationWithoutFragment) {
      const element = view.element;
      element.setAttribute('aria-hidden', 'true');
      element.classList.add('ion-page-hidden');
      view.ref.changeDetectorRef.detach();
    }
  });
};
class Config {
  get(key, fallback) {
    const c = getConfig();
    if (c) {
      return c.get(key, fallback);
    }
    return null;
  }
  getBoolean(key, fallback) {
    const c = getConfig();
    if (c) {
      return c.getBoolean(key, fallback);
    }
    return false;
  }
  getNumber(key, fallback) {
    const c = getConfig();
    if (c) {
      return c.getNumber(key, fallback);
    }
    return 0;
  }
}
/** @nocollapse */
Config.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: Config,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
Config.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: Config,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: Config,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }]
});
const ConfigToken = new InjectionToken('USERCONFIG');
const getConfig = () => {
  if (typeof window !== 'undefined') {
    const Ionic = window.Ionic;
    if (Ionic === null || Ionic === void 0 ? void 0 : Ionic.config) {
      return Ionic.config;
    }
  }
  return null;
};
class Platform {
  constructor(doc, zone) {
    this.doc = doc;
    /**
     * @hidden
     */
    this.backButton = new Subject();
    /**
     * The keyboardDidShow event emits when the
     * on-screen keyboard is presented.
     */
    this.keyboardDidShow = new Subject();
    /**
     * The keyboardDidHide event emits when the
     * on-screen keyboard is hidden.
     */
    this.keyboardDidHide = new Subject();
    /**
     * The pause event emits when the native platform puts the application
     * into the background, typically when the user switches to a different
     * application. This event would emit when a Cordova app is put into
     * the background, however, it would not fire on a standard web browser.
     */
    this.pause = new Subject();
    /**
     * The resume event emits when the native platform pulls the application
     * out from the background. This event would emit when a Cordova app comes
     * out from the background, however, it would not fire on a standard web browser.
     */
    this.resume = new Subject();
    /**
     * The resize event emits when the browser window has changed dimensions. This
     * could be from a browser window being physically resized, or from a device
     * changing orientation.
     */
    this.resize = new Subject();
    zone.run(() => {
      var _a;
      this.win = doc.defaultView;
      this.backButton.subscribeWithPriority = function (priority, callback) {
        return this.subscribe(ev => {
          return ev.register(priority, processNextHandler => zone.run(() => callback(processNextHandler)));
        });
      };
      proxyEvent(this.pause, doc, 'pause');
      proxyEvent(this.resume, doc, 'resume');
      proxyEvent(this.backButton, doc, 'ionBackButton');
      proxyEvent(this.resize, this.win, 'resize');
      proxyEvent(this.keyboardDidShow, this.win, 'ionKeyboardDidShow');
      proxyEvent(this.keyboardDidHide, this.win, 'ionKeyboardDidHide');
      let readyResolve;
      this._readyPromise = new Promise(res => {
        readyResolve = res;
      });
      if ((_a = this.win) === null || _a === void 0 ? void 0 : _a['cordova']) {
        doc.addEventListener('deviceready', () => {
          readyResolve('cordova');
        }, {
          once: true
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        readyResolve('dom');
      }
    });
  }
  /**
   * @returns returns true/false based on platform.
   * @description
   * Depending on the platform the user is on, `is(platformName)` will
   * return `true` or `false`. Note that the same app can return `true`
   * for more than one platform name. For example, an app running from
   * an iPad would return `true` for the platform names: `mobile`,
   * `ios`, `ipad`, and `tablet`. Additionally, if the app was running
   * from Cordova then `cordova` would be true, and if it was running
   * from a web browser on the iPad then `mobileweb` would be `true`.
   *
   * ```
   * import { Platform } from 'ionic-angular';
   *
   * @Component({...})
   * export MyPage {
   *   constructor(public platform: Platform) {
   *     if (this.platform.is('ios')) {
   *       // This will only print when on iOS
   *       console.log('I am an iOS device!');
   *     }
   *   }
   * }
   * ```
   *
   * | Platform Name   | Description                        |
   * |-----------------|------------------------------------|
   * | android         | on a device running Android.       |
   * | capacitor       | on a device running Capacitor.     |
   * | cordova         | on a device running Cordova.       |
   * | ios             | on a device running iOS.           |
   * | ipad            | on an iPad device.                 |
   * | iphone          | on an iPhone device.               |
   * | phablet         | on a phablet device.               |
   * | tablet          | on a tablet device.                |
   * | electron        | in Electron on a desktop device.   |
   * | pwa             | as a PWA app.                      |
   * | mobile          | on a mobile device.                |
   * | mobileweb       | on a mobile device in a browser.   |
   * | desktop         | on a desktop device.               |
   * | hybrid          | is a cordova or capacitor app.     |
   *
   */
  is(platformName) {
    return isPlatform(this.win, platformName);
  }
  /**
   * @returns the array of platforms
   * @description
   * Depending on what device you are on, `platforms` can return multiple values.
   * Each possible value is a hierarchy of platforms. For example, on an iPhone,
   * it would return `mobile`, `ios`, and `iphone`.
   *
   * ```
   * import { Platform } from 'ionic-angular';
   *
   * @Component({...})
   * export MyPage {
   *   constructor(public platform: Platform) {
   *     // This will print an array of the current platforms
   *     console.log(this.platform.platforms());
   *   }
   * }
   * ```
   */
  platforms() {
    return getPlatforms(this.win);
  }
  /**
   * Returns a promise when the platform is ready and native functionality
   * can be called. If the app is running from within a web browser, then
   * the promise will resolve when the DOM is ready. When the app is running
   * from an application engine such as Cordova, then the promise will
   * resolve when Cordova triggers the `deviceready` event.
   *
   * The resolved value is the `readySource`, which states which platform
   * ready was used. For example, when Cordova is ready, the resolved ready
   * source is `cordova`. The default ready source value will be `dom`. The
   * `readySource` is useful if different logic should run depending on the
   * platform the app is running from. For example, only Cordova can execute
   * the status bar plugin, so the web should not run status bar plugin logic.
   *
   * ```
   * import { Component } from '@angular/core';
   * import { Platform } from 'ionic-angular';
   *
   * @Component({...})
   * export MyApp {
   *   constructor(public platform: Platform) {
   *     this.platform.ready().then((readySource) => {
   *       console.log('Platform ready from', readySource);
   *       // Platform now ready, execute any required native code
   *     });
   *   }
   * }
   * ```
   */
  ready() {
    return this._readyPromise;
  }
  /**
   * Returns if this app is using right-to-left language direction or not.
   * We recommend the app's `index.html` file already has the correct `dir`
   * attribute value set, such as `<html dir="ltr">` or `<html dir="rtl">`.
   * [W3C: Structural markup and right-to-left text in HTML](http://www.w3.org/International/questions/qa-html-dir)
   */
  get isRTL() {
    return this.doc.dir === 'rtl';
  }
  /**
   * Get the query string parameter
   */
  getQueryParam(key) {
    return readQueryParam(this.win.location.href, key);
  }
  /**
   * Returns `true` if the app is in landscape mode.
   */
  isLandscape() {
    return !this.isPortrait();
  }
  /**
   * Returns `true` if the app is in portrait mode.
   */
  isPortrait() {
    var _a, _b;
    return (_b = (_a = this.win).matchMedia) === null || _b === void 0 ? void 0 : _b.call(_a, '(orientation: portrait)').matches;
  }
  testUserAgent(expression) {
    const nav = this.win.navigator;
    return !!((nav === null || nav === void 0 ? void 0 : nav.userAgent) && nav.userAgent.indexOf(expression) >= 0);
  }
  /**
   * Get the current url.
   */
  url() {
    return this.win.location.href;
  }
  /**
   * Gets the width of the platform's viewport using `window.innerWidth`.
   */
  width() {
    return this.win.innerWidth;
  }
  /**
   * Gets the height of the platform's viewport using `window.innerHeight`.
   */
  height() {
    return this.win.innerHeight;
  }
}
/** @nocollapse */
Platform.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: Platform,
  deps: [{
    token: DOCUMENT
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
Platform.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: Platform,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: Platform,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: i0.NgZone
    }];
  }
});
const readQueryParam = (url, key) => {
  key = key.replace(/[[\]\\]/g, '\\$&');
  const regex = new RegExp('[\\?&]' + key + '=([^&#]*)');
  const results = regex.exec(url);
  return results ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : null;
};
const proxyEvent = (emitter, el, eventName) => {
  if (el) {
    el.addEventListener(eventName, ev => {
      // ?? cordova might emit "null" events
      emitter.next(ev != null ? ev.detail : undefined);
    });
  }
};
class NavController {
  constructor(platform, location, serializer, router) {
    this.location = location;
    this.serializer = serializer;
    this.router = router;
    this.direction = DEFAULT_DIRECTION;
    this.animated = DEFAULT_ANIMATED;
    this.guessDirection = 'forward';
    this.lastNavId = -1;
    // Subscribe to router events to detect direction
    if (router) {
      router.events.subscribe(ev => {
        if (ev instanceof NavigationStart) {
          const id = ev.restoredState ? ev.restoredState.navigationId : ev.id;
          this.guessDirection = id < this.lastNavId ? 'back' : 'forward';
          this.guessAnimation = !ev.restoredState ? this.guessDirection : undefined;
          this.lastNavId = this.guessDirection === 'forward' ? ev.id : id;
        }
      });
    }
    // Subscribe to backButton events
    platform.backButton.subscribeWithPriority(0, processNextHandler => {
      this.pop();
      processNextHandler();
    });
  }
  /**
   * This method uses Angular's [Router](https://angular.io/api/router/Router) under the hood,
   * it's equivalent to calling `this.router.navigateByUrl()`, but it's explicit about the **direction** of the transition.
   *
   * Going **forward** means that a new page is going to be pushed to the stack of the outlet (ion-router-outlet),
   * and that it will show a "forward" animation by default.
   *
   * Navigating forward can also be triggered in a declarative manner by using the `[routerDirection]` directive:
   *
   * ```html
   * <a routerLink="/path/to/page" routerDirection="forward">Link</a>
   * ```
   */
  navigateForward(url, options = {}) {
    this.setDirection('forward', options.animated, options.animationDirection, options.animation);
    return this.navigate(url, options);
  }
  /**
   * This method uses Angular's [Router](https://angular.io/api/router/Router) under the hood,
   * it's equivalent to calling:
   *
   * ```ts
   * this.navController.setDirection('back');
   * this.router.navigateByUrl(path);
   * ```
   *
   * Going **back** means that all the pages in the stack until the navigated page is found will be popped,
   * and that it will show a "back" animation by default.
   *
   * Navigating back can also be triggered in a declarative manner by using the `[routerDirection]` directive:
   *
   * ```html
   * <a routerLink="/path/to/page" routerDirection="back">Link</a>
   * ```
   */
  navigateBack(url, options = {}) {
    this.setDirection('back', options.animated, options.animationDirection, options.animation);
    return this.navigate(url, options);
  }
  /**
   * This method uses Angular's [Router](https://angular.io/api/router/Router) under the hood,
   * it's equivalent to calling:
   *
   * ```ts
   * this.navController.setDirection('root');
   * this.router.navigateByUrl(path);
   * ```
   *
   * Going **root** means that all existing pages in the stack will be removed,
   * and the navigated page will become the single page in the stack.
   *
   * Navigating root can also be triggered in a declarative manner by using the `[routerDirection]` directive:
   *
   * ```html
   * <a routerLink="/path/to/page" routerDirection="root">Link</a>
   * ```
   */
  navigateRoot(url, options = {}) {
    this.setDirection('root', options.animated, options.animationDirection, options.animation);
    return this.navigate(url, options);
  }
  /**
   * Same as [Location](https://angular.io/api/common/Location)'s back() method.
   * It will use the standard `window.history.back()` under the hood, but featuring a `back` animation
   * by default.
   */
  back(options = {
    animated: true,
    animationDirection: 'back'
  }) {
    this.setDirection('back', options.animated, options.animationDirection, options.animation);
    return this.location.back();
  }
  /**
   * This methods goes back in the context of Ionic's stack navigation.
   *
   * It recursively finds the top active `ion-router-outlet` and calls `pop()`.
   * This is the recommended way to go back when you are using `ion-router-outlet`.
   */
  pop() {
    return __awaiter(this, void 0, void 0, function* () {
      let outlet = this.topOutlet;
      while (outlet) {
        if (yield outlet.pop()) {
          break;
        } else {
          outlet = outlet.parentOutlet;
        }
      }
    });
  }
  /**
   * This methods specifies the direction of the next navigation performed by the Angular router.
   *
   * `setDirection()` does not trigger any transition, it just sets some flags to be consumed by `ion-router-outlet`.
   *
   * It's recommended to use `navigateForward()`, `navigateBack()` and `navigateRoot()` instead of `setDirection()`.
   */
  setDirection(direction, animated, animationDirection, animationBuilder) {
    this.direction = direction;
    this.animated = getAnimation(direction, animated, animationDirection);
    this.animationBuilder = animationBuilder;
  }
  /**
   * @internal
   */
  setTopOutlet(outlet) {
    this.topOutlet = outlet;
  }
  /**
   * @internal
   */
  consumeTransition() {
    let direction = 'root';
    let animation;
    const animationBuilder = this.animationBuilder;
    if (this.direction === 'auto') {
      direction = this.guessDirection;
      animation = this.guessAnimation;
    } else {
      animation = this.animated;
      direction = this.direction;
    }
    this.direction = DEFAULT_DIRECTION;
    this.animated = DEFAULT_ANIMATED;
    this.animationBuilder = undefined;
    return {
      direction,
      animation,
      animationBuilder
    };
  }
  navigate(url, options) {
    if (Array.isArray(url)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.router.navigate(url, options);
    } else {
      /**
       * navigateByUrl ignores any properties that
       * would change the url, so things like queryParams
       * would be ignored unless we create a url tree
       * More Info: https://github.com/angular/angular/issues/18798
       */
      const urlTree = this.serializer.parse(url.toString());
      if (options.queryParams !== undefined) {
        urlTree.queryParams = Object.assign({}, options.queryParams);
      }
      if (options.fragment !== undefined) {
        urlTree.fragment = options.fragment;
      }
      /**
       * `navigateByUrl` will still apply `NavigationExtras` properties
       * that do not modify the url, such as `replaceUrl` which is why
       * `options` is passed in here.
       */
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.router.navigateByUrl(urlTree, options);
    }
  }
}
/** @nocollapse */
NavController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: NavController,
  deps: [{
    token: Platform
  }, {
    token: i1.Location
  }, {
    token: i3.UrlSerializer
  }, {
    token: i3.Router,
    optional: true
  }],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
NavController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: NavController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: NavController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: Platform
    }, {
      type: i1.Location
    }, {
      type: i3.UrlSerializer
    }, {
      type: i3.Router,
      decorators: [{
        type: Optional
      }]
    }];
  }
});
const getAnimation = (direction, animated, animationDirection) => {
  if (animated === false) {
    return undefined;
  }
  if (animationDirection !== undefined) {
    return animationDirection;
  }
  if (direction === 'forward' || direction === 'back') {
    return direction;
  } else if (direction === 'root' && animated === true) {
    return 'forward';
  }
  return undefined;
};
const DEFAULT_DIRECTION = 'auto';
const DEFAULT_ANIMATED = undefined;

// TODO(FW-2827): types
// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonRouterOutlet {
  constructor(parentContexts, location, name, tabs, config, navCtrl, componentFactoryResolver, commonLocation, elementRef, router, zone, activatedRoute, parentOutlet) {
    this.parentContexts = parentContexts;
    this.location = location;
    this.config = config;
    this.navCtrl = navCtrl;
    this.componentFactoryResolver = componentFactoryResolver;
    this.parentOutlet = parentOutlet;
    this.activated = null;
    this.activatedView = null;
    this._activatedRoute = null;
    // Maintain map of activated route proxies for each component instance
    this.proxyMap = new WeakMap();
    // Keep the latest activated route in a subject for the proxy routes to switch map to
    this.currentActivatedRoute$ = new BehaviorSubject(null);
    this.stackEvents = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-rename
    this.activateEvents = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-rename
    this.deactivateEvents = new EventEmitter();
    this.nativeEl = elementRef.nativeElement;
    this.name = name || PRIMARY_OUTLET;
    this.tabsPrefix = tabs === 'true' ? getUrl(router, activatedRoute) : undefined;
    this.stackCtrl = new StackController(this.tabsPrefix, this.nativeEl, router, navCtrl, zone, commonLocation);
    parentContexts.onChildOutletCreated(this.name, this);
  }
  set animation(animation) {
    this.nativeEl.animation = animation;
  }
  set animated(animated) {
    this.nativeEl.animated = animated;
  }
  set swipeGesture(swipe) {
    this._swipeGesture = swipe;
    this.nativeEl.swipeHandler = swipe ? {
      canStart: () => this.stackCtrl.canGoBack(1) && !this.stackCtrl.hasRunningTask(),
      onStart: () => this.stackCtrl.startBackTransition(),
      onEnd: shouldContinue => this.stackCtrl.endBackTransition(shouldContinue)
    } : undefined;
  }
  ngOnDestroy() {
    this.stackCtrl.destroy();
  }
  getContext() {
    return this.parentContexts.getContext(this.name);
  }
  ngOnInit() {
    if (!this.activated) {
      // If the outlet was not instantiated at the time the route got activated we need to populate
      // the outlet when it is initialized (ie inside a NgIf)
      const context = this.getContext();
      if (context === null || context === void 0 ? void 0 : context.route) {
        this.activateWith(context.route, context.resolver || null);
      }
    }
    new Promise(resolve => componentOnReady(this.nativeEl, resolve)).then(() => {
      if (this._swipeGesture === undefined) {
        this.swipeGesture = this.config.getBoolean('swipeBackEnabled', this.nativeEl.mode === 'ios');
      }
    });
  }
  get isActivated() {
    return !!this.activated;
  }
  get component() {
    if (!this.activated) {
      throw new Error('Outlet is not activated');
    }
    return this.activated.instance;
  }
  get activatedRoute() {
    if (!this.activated) {
      throw new Error('Outlet is not activated');
    }
    return this._activatedRoute;
  }
  get activatedRouteData() {
    if (this._activatedRoute) {
      return this._activatedRoute.snapshot.data;
    }
    return {};
  }
  /**
   * Called when the `RouteReuseStrategy` instructs to detach the subtree
   */
  detach() {
    throw new Error('incompatible reuse strategy');
  }
  /**
   * Called when the `RouteReuseStrategy` instructs to re-attach a previously detached subtree
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  attach(_ref, _activatedRoute) {
    throw new Error('incompatible reuse strategy');
  }
  deactivate() {
    if (this.activated) {
      if (this.activatedView) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const context = this.getContext();
        this.activatedView.savedData = new Map(context.children['contexts']);
        /**
         * Angular v11.2.10 introduced a change
         * where this route context is cleared out when
         * a router-outlet is deactivated, However,
         * we need this route information in order to
         * return a user back to the correct tab when
         * leaving and then going back to the tab context.
         */
        const primaryOutlet = this.activatedView.savedData.get('primary');
        if (primaryOutlet && context.route) {
          primaryOutlet.route = Object.assign({}, context.route);
        }
        /**
         * Ensure we are saving the NavigationExtras
         * data otherwise it will be lost
         */
        this.activatedView.savedExtras = {};
        if (context.route) {
          const contextSnapshot = context.route.snapshot;
          this.activatedView.savedExtras.queryParams = contextSnapshot.queryParams;
          this.activatedView.savedExtras.fragment = contextSnapshot.fragment;
        }
      }
      const c = this.component;
      this.activatedView = null;
      this.activated = null;
      this._activatedRoute = null;
      this.deactivateEvents.emit(c);
    }
  }
  activateWith(activatedRoute, resolverOrInjector) {
    var _a;
    if (this.isActivated) {
      throw new Error('Cannot activate an already activated outlet');
    }
    this._activatedRoute = activatedRoute;
    let cmpRef;
    let enteringView = this.stackCtrl.getExistingView(activatedRoute);
    if (enteringView) {
      cmpRef = this.activated = enteringView.ref;
      const saved = enteringView.savedData;
      if (saved) {
        // self-restore
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const context = this.getContext();
        context.children['contexts'] = saved;
      }
      // Updated activated route proxy for this component
      this.updateActivatedRouteProxy(cmpRef.instance, activatedRoute);
    } else {
      const snapshot = activatedRoute._futureSnapshot;
      /**
       * Angular 14 introduces a new `loadComponent` property to the route config.
       * This function will assign a `component` property to the route snapshot.
       * We check for the presence of this property to determine if the route is
       * using standalone components.
       */
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (snapshot.routeConfig.component == null && this.environmentInjector == null) {
        console.warn('[Ionic Warning]: You must supply an environmentInjector to use standalone components with routing:\n\n' + 'In your component class, add:\n\n' + `   import { EnvironmentInjector } from '@angular/core';\n` + '   constructor(public environmentInjector: EnvironmentInjector) {}\n' + '\n' + 'In your router outlet template, add:\n\n' + '   <ion-router-outlet [environmentInjector]="environmentInjector"></ion-router-outlet>\n\n' + 'Alternatively, if you are routing within ion-tabs:\n\n' + '   <ion-tabs [environmentInjector]="environmentInjector"></ion-tabs>');
        return;
      }
      const childContexts = this.parentContexts.getOrCreateContext(this.name).children;
      // We create an activated route proxy object that will maintain future updates for this component
      // over its lifecycle in the stack.
      const component$ = new BehaviorSubject(null);
      const activatedRouteProxy = this.createActivatedRouteProxy(component$, activatedRoute);
      const injector = new OutletInjector(activatedRouteProxy, childContexts, this.location.injector);
      /**
       * The resolver is not always provided and is required in Angular 12.
       * Fallback to the class-level provider when the resolver is not set.
       */
      resolverOrInjector = resolverOrInjector || this.componentFactoryResolver;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const component = (_a = snapshot.routeConfig.component) !== null && _a !== void 0 ? _a : snapshot.component;
      if (resolverOrInjector && isComponentFactoryResolver(resolverOrInjector)) {
        // Backwards compatibility for Angular 13 and lower
        const factory = resolverOrInjector.resolveComponentFactory(component);
        cmpRef = this.activated = this.location.createComponent(factory, this.location.length, injector);
      } else {
        /**
         * Angular 14 and higher.
         *
         * TODO: FW-1641: Migrate once Angular 13 support is dropped.
         *
         * When we drop < Angular 14, we can replace the following code with:
         * ```ts
          const environmentInjector = resolverOrInjector ?? this.environmentInjector;
            cmpRef = this.activated = location.createComponent(component, {
              index: location.length,
              injector,
              environmentInjector,
            });
         * ```
         * where `this.environmentInjector` is a provider of `EnvironmentInjector` from @angular/core.
         */
        const environmentInjector = resolverOrInjector !== null && resolverOrInjector !== void 0 ? resolverOrInjector : this.environmentInjector;
        cmpRef = this.activated = this.location.createComponent(component, {
          index: this.location.length,
          injector,
          environmentInjector
        });
      }
      // Once the component is created we can push it to our local subject supplied to the proxy
      component$.next(cmpRef.instance);
      // Calling `markForCheck` to make sure we will run the change detection when the
      // `RouterOutlet` is inside a `ChangeDetectionStrategy.OnPush` component.
      enteringView = this.stackCtrl.createView(this.activated, activatedRoute);
      // Store references to the proxy by component
      this.proxyMap.set(cmpRef.instance, activatedRouteProxy);
      this.currentActivatedRoute$.next({
        component: cmpRef.instance,
        activatedRoute
      });
    }
    this.activatedView = enteringView;
    /**
     * The top outlet is set prior to the entering view's transition completing,
     * so that when we have nested outlets (e.g. ion-tabs inside an ion-router-outlet),
     * the tabs outlet will be assigned as the top outlet when a view inside tabs is
     * activated.
     *
     * In this scenario, activeWith is called for both the tabs and the root router outlet.
     * To avoid a race condition, we assign the top outlet synchronously.
     */
    this.navCtrl.setTopOutlet(this);
    this.stackCtrl.setActive(enteringView).then(data => {
      this.activateEvents.emit(cmpRef.instance);
      this.stackEvents.emit(data);
    });
  }
  /**
   * Returns `true` if there are pages in the stack to go back.
   */
  canGoBack(deep = 1, stackId) {
    return this.stackCtrl.canGoBack(deep, stackId);
  }
  /**
   * Resolves to `true` if it the outlet was able to sucessfully pop the last N pages.
   */
  pop(deep = 1, stackId) {
    return this.stackCtrl.pop(deep, stackId);
  }
  /**
   * Returns the URL of the active page of each stack.
   */
  getLastUrl(stackId) {
    const active = this.stackCtrl.getLastUrl(stackId);
    return active ? active.url : undefined;
  }
  /**
   * Returns the RouteView of the active page of each stack.
   * @internal
   */
  getLastRouteView(stackId) {
    return this.stackCtrl.getLastUrl(stackId);
  }
  /**
   * Returns the root view in the tab stack.
   * @internal
   */
  getRootView(stackId) {
    return this.stackCtrl.getRootUrl(stackId);
  }
  /**
   * Returns the active stack ID. In the context of ion-tabs, it means the active tab.
   */
  getActiveStackId() {
    return this.stackCtrl.getActiveStackId();
  }
  /**
   * Since the activated route can change over the life time of a component in an ion router outlet, we create
   * a proxy so that we can update the values over time as a user navigates back to components already in the stack.
   */
  createActivatedRouteProxy(component$, activatedRoute) {
    const proxy = new ActivatedRoute();
    proxy._futureSnapshot = activatedRoute._futureSnapshot;
    proxy._routerState = activatedRoute._routerState;
    proxy.snapshot = activatedRoute.snapshot;
    proxy.outlet = activatedRoute.outlet;
    proxy.component = activatedRoute.component;
    // Setup wrappers for the observables so consumers don't have to worry about switching to new observables as the state updates
    proxy._paramMap = this.proxyObservable(component$, 'paramMap');
    proxy._queryParamMap = this.proxyObservable(component$, 'queryParamMap');
    proxy.url = this.proxyObservable(component$, 'url');
    proxy.params = this.proxyObservable(component$, 'params');
    proxy.queryParams = this.proxyObservable(component$, 'queryParams');
    proxy.fragment = this.proxyObservable(component$, 'fragment');
    proxy.data = this.proxyObservable(component$, 'data');
    return proxy;
  }
  /**
   * Create a wrapped observable that will switch to the latest activated route matched by the given component
   */
  proxyObservable(component$, path) {
    return component$.pipe(
    // First wait until the component instance is pushed
    filter(component => !!component), switchMap(component => this.currentActivatedRoute$.pipe(filter(current => current !== null && current.component === component), switchMap(current => current && current.activatedRoute[path]), distinctUntilChanged())));
  }
  /**
   * Updates the activated route proxy for the given component to the new incoming router state
   */
  updateActivatedRouteProxy(component, activatedRoute) {
    const proxy = this.proxyMap.get(component);
    if (!proxy) {
      throw new Error(`Could not find activated route proxy for view`);
    }
    proxy._futureSnapshot = activatedRoute._futureSnapshot;
    proxy._routerState = activatedRoute._routerState;
    proxy.snapshot = activatedRoute.snapshot;
    proxy.outlet = activatedRoute.outlet;
    proxy.component = activatedRoute.component;
    this.currentActivatedRoute$.next({
      component,
      activatedRoute
    });
  }
}
/** @nocollapse */
IonRouterOutlet.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRouterOutlet,
  deps: [{
    token: i3.ChildrenOutletContexts
  }, {
    token: i0.ViewContainerRef
  }, {
    token: 'name',
    attribute: true
  }, {
    token: 'tabs',
    attribute: true,
    optional: true
  }, {
    token: Config
  }, {
    token: NavController
  }, {
    token: i0.ComponentFactoryResolver,
    optional: true
  }, {
    token: i1.Location
  }, {
    token: i0.ElementRef
  }, {
    token: i3.Router
  }, {
    token: i0.NgZone
  }, {
    token: i3.ActivatedRoute
  }, {
    token: IonRouterOutlet,
    optional: true,
    skipSelf: true
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
IonRouterOutlet.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonRouterOutlet,
  selector: "ion-router-outlet",
  inputs: {
    animated: "animated",
    animation: "animation",
    mode: "mode",
    swipeGesture: "swipeGesture",
    environmentInjector: "environmentInjector"
  },
  outputs: {
    stackEvents: "stackEvents",
    activateEvents: "activate",
    deactivateEvents: "deactivate"
  },
  exportAs: ["outlet"],
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonRouterOutlet,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ion-router-outlet',
      exportAs: 'outlet',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'animation', 'mode', 'swipeGesture']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i3.ChildrenOutletContexts
    }, {
      type: i0.ViewContainerRef
    }, {
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['name']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Attribute,
        args: ['tabs']
      }]
    }, {
      type: Config
    }, {
      type: NavController
    }, {
      type: i0.ComponentFactoryResolver,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i1.Location
    }, {
      type: i0.ElementRef
    }, {
      type: i3.Router
    }, {
      type: i0.NgZone
    }, {
      type: i3.ActivatedRoute
    }, {
      type: IonRouterOutlet,
      decorators: [{
        type: SkipSelf
      }, {
        type: Optional
      }]
    }];
  },
  propDecorators: {
    environmentInjector: [{
      type: Input
    }],
    stackEvents: [{
      type: Output
    }],
    activateEvents: [{
      type: Output,
      args: ['activate']
    }],
    deactivateEvents: [{
      type: Output,
      args: ['deactivate']
    }]
  }
});
class OutletInjector {
  constructor(route, childContexts, parent) {
    this.route = route;
    this.childContexts = childContexts;
    this.parent = parent;
  }
  get(token, notFoundValue) {
    if (token === ActivatedRoute) {
      return this.route;
    }
    if (token === ChildrenOutletContexts) {
      return this.childContexts;
    }
    return this.parent.get(token, notFoundValue);
  }
}

// eslint-disable-next-line @angular-eslint/component-class-suffix
class IonTabs {
  constructor(navCtrl) {
    this.navCtrl = navCtrl;
    this.ionTabsWillChange = new EventEmitter();
    this.ionTabsDidChange = new EventEmitter();
  }
  /**
   * @internal
   */
  onPageSelected(detail) {
    const stackId = detail.enteringView.stackId;
    if (detail.tabSwitch && stackId !== undefined) {
      this.ionTabsWillChange.emit({
        tab: stackId
      });
      if (this.tabBar) {
        this.tabBar.selectedTab = stackId;
      }
      this.ionTabsDidChange.emit({
        tab: stackId
      });
    }
  }
  /**
   * When a tab button is clicked, there are several scenarios:
   * 1. If the selected tab is currently active (the tab button has been clicked
   *    again), then it should go to the root view for that tab.
   *
   *   a. Get the saved root view from the router outlet. If the saved root view
   *      matches the tabRootUrl, set the route view to this view including the
   *      navigation extras.
   *   b. If the saved root view from the router outlet does
   *      not match, navigate to the tabRootUrl. No navigation extras are
   *      included.
   *
   * 2. If the current tab tab is not currently selected, get the last route
   *    view from the router outlet.
   *
   *   a. If the last route view exists, navigate to that view including any
   *      navigation extras
   *   b. If the last route view doesn't exist, then navigate
   *      to the default tabRootUrl
   */
  select(tabOrEvent) {
    const isTabString = typeof tabOrEvent === 'string';
    const tab = isTabString ? tabOrEvent : tabOrEvent.detail.tab;
    const alreadySelected = this.outlet.getActiveStackId() === tab;
    const tabRootUrl = `${this.outlet.tabsPrefix}/${tab}`;
    /**
     * If this is a nested tab, prevent the event
     * from bubbling otherwise the outer tabs
     * will respond to this event too, causing
     * the app to get directed to the wrong place.
     */
    if (!isTabString) {
      tabOrEvent.stopPropagation();
    }
    if (alreadySelected) {
      const activeStackId = this.outlet.getActiveStackId();
      const activeView = this.outlet.getLastRouteView(activeStackId);
      // If on root tab, do not navigate to root tab again
      if ((activeView === null || activeView === void 0 ? void 0 : activeView.url) === tabRootUrl) {
        return;
      }
      const rootView = this.outlet.getRootView(tab);
      const navigationExtras = rootView && tabRootUrl === rootView.url && rootView.savedExtras;
      return this.navCtrl.navigateRoot(tabRootUrl, Object.assign(Object.assign({}, navigationExtras), {
        animated: true,
        animationDirection: 'back'
      }));
    } else {
      const lastRoute = this.outlet.getLastRouteView(tab);
      /**
       * If there is a lastRoute, goto that, otherwise goto the fallback url of the
       * selected tab
       */
      const url = (lastRoute === null || lastRoute === void 0 ? void 0 : lastRoute.url) || tabRootUrl;
      const navigationExtras = lastRoute === null || lastRoute === void 0 ? void 0 : lastRoute.savedExtras;
      return this.navCtrl.navigateRoot(url, Object.assign(Object.assign({}, navigationExtras), {
        animated: true,
        animationDirection: 'back'
      }));
    }
  }
  getSelected() {
    return this.outlet.getActiveStackId();
  }
}
/** @nocollapse */
IonTabs.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonTabs,
  deps: [{
    token: NavController
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTabs.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonTabs,
  selector: "ion-tabs",
  inputs: {
    environmentInjector: "environmentInjector"
  },
  outputs: {
    ionTabsWillChange: "ionTabsWillChange",
    ionTabsDidChange: "ionTabsDidChange"
  },
  host: {
    listeners: {
      "ionTabButtonClick": "select($event)"
    }
  },
  queries: [{
    propertyName: "tabBar",
    first: true,
    predicate: IonTabBar,
    descendants: true
  }],
  viewQueries: [{
    propertyName: "outlet",
    first: true,
    predicate: ["outlet"],
    descendants: true,
    read: IonRouterOutlet
  }],
  ngImport: i0,
  template: ` <ng-content select="[slot=top]"></ng-content>
    <div class="tabs-inner">
      <ion-router-outlet
        #outlet
        tabs="true"
        [environmentInjector]="environmentInjector"
        (stackEvents)="onPageSelected($event)"
      ></ion-router-outlet>
    </div>
    <ng-content></ng-content>`,
  isInline: true,
  styles: ["\n      :host {\n        display: flex;\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n\n        flex-direction: column;\n\n        width: 100%;\n        height: 100%;\n\n        contain: layout size style;\n        z-index: $z-index-page-container;\n      }\n      .tabs-inner {\n        position: relative;\n\n        flex: 1;\n\n        contain: layout size style;\n      }\n    "],
  directives: [{
    type: IonRouterOutlet,
    selector: "ion-router-outlet",
    inputs: ["animated", "animation", "mode", "swipeGesture", "environmentInjector"],
    outputs: ["stackEvents", "activate", "deactivate"],
    exportAs: ["outlet"]
  }]
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonTabs,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-tabs',
      template: ` <ng-content select="[slot=top]"></ng-content>
    <div class="tabs-inner">
      <ion-router-outlet
        #outlet
        tabs="true"
        [environmentInjector]="environmentInjector"
        (stackEvents)="onPageSelected($event)"
      ></ion-router-outlet>
    </div>
    <ng-content></ng-content>`,
      styles: [`
      :host {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        flex-direction: column;

        width: 100%;
        height: 100%;

        contain: layout size style;
        z-index: $z-index-page-container;
      }
      .tabs-inner {
        position: relative;

        flex: 1;

        contain: layout size style;
      }
    `]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: NavController
    }];
  },
  propDecorators: {
    outlet: [{
      type: ViewChild,
      args: ['outlet', {
        read: IonRouterOutlet,
        static: false
      }]
    }],
    tabBar: [{
      type: ContentChild,
      args: [IonTabBar, {
        static: false
      }]
    }],
    environmentInjector: [{
      type: Input
    }],
    ionTabsWillChange: [{
      type: Output
    }],
    ionTabsDidChange: [{
      type: Output
    }],
    select: [{
      type: HostListener,
      args: ['ionTabButtonClick', ['$event']]
    }]
  }
});
class IonBackButtonDelegateDirective {
  constructor(routerOutlet, navCtrl, config) {
    this.routerOutlet = routerOutlet;
    this.navCtrl = navCtrl;
    this.config = config;
  }
  /**
   * @internal
   */
  onClick(ev) {
    var _a;
    const defaultHref = this.defaultHref || this.config.get('backButtonDefaultHref');
    if ((_a = this.routerOutlet) === null || _a === void 0 ? void 0 : _a.canGoBack()) {
      this.navCtrl.setDirection('back', undefined, undefined, this.routerAnimation);
      this.routerOutlet.pop();
      ev.preventDefault();
    } else if (defaultHref != null) {
      this.navCtrl.navigateBack(defaultHref, {
        animation: this.routerAnimation
      });
      ev.preventDefault();
    }
  }
}
/** @nocollapse */
IonBackButtonDelegateDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonBackButtonDelegateDirective,
  deps: [{
    token: IonRouterOutlet,
    optional: true
  }, {
    token: NavController
  }, {
    token: Config
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
IonBackButtonDelegateDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonBackButtonDelegateDirective,
  selector: "ion-back-button",
  inputs: {
    defaultHref: "defaultHref",
    routerAnimation: "routerAnimation"
  },
  host: {
    listeners: {
      "click": "onClick($event)"
    }
  },
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonBackButtonDelegateDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ion-back-button'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: IonRouterOutlet,
      decorators: [{
        type: Optional
      }]
    }, {
      type: NavController
    }, {
      type: Config
    }];
  },
  propDecorators: {
    defaultHref: [{
      type: Input
    }],
    routerAnimation: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  }
});
let NavDelegate = class NavDelegate {
  constructor(ref, resolver, injector, angularDelegate, location) {
    this.el = ref.nativeElement;
    ref.nativeElement.delegate = angularDelegate.create(resolver, injector, location);
    proxyOutputs(this, this.el, ['ionNavDidChange', 'ionNavWillChange']);
  }
};
/** @nocollapse */
NavDelegate.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: NavDelegate,
  deps: [{
    token: i0.ElementRef
  }, {
    token: i0.ComponentFactoryResolver
  }, {
    token: i0.Injector
  }, {
    token: AngularDelegate
  }, {
    token: i0.ViewContainerRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
NavDelegate.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: NavDelegate,
  selector: "ion-nav",
  ngImport: i0
});
NavDelegate = __decorate([ProxyCmp({
  inputs: ['animated', 'animation', 'root', 'rootParams', 'swipeGesture'],
  methods: ['push', 'insert', 'insertPages', 'pop', 'popTo', 'popToRoot', 'removeIndex', 'setRoot', 'setPages', 'getActive', 'getByIndex', 'canGoBack', 'getPrevious']
})], NavDelegate);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: NavDelegate,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ion-nav'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.ComponentFactoryResolver
    }, {
      type: i0.Injector
    }, {
      type: AngularDelegate
    }, {
      type: i0.ViewContainerRef
    }];
  }
});

/**
 * Adds support for Ionic routing directions and animations to the base Angular router link directive.
 *
 * When the router link is clicked, the directive will assign the direction and
 * animation so that the routing integration will transition correctly.
 */
class RouterLinkDelegateDirective {
  constructor(locationStrategy, navCtrl, elementRef, router, routerLink) {
    this.locationStrategy = locationStrategy;
    this.navCtrl = navCtrl;
    this.elementRef = elementRef;
    this.router = router;
    this.routerLink = routerLink;
    this.routerDirection = 'forward';
  }
  ngOnInit() {
    this.updateTargetUrlAndHref();
  }
  ngOnChanges() {
    this.updateTargetUrlAndHref();
  }
  updateTargetUrlAndHref() {
    var _a;
    if ((_a = this.routerLink) === null || _a === void 0 ? void 0 : _a.urlTree) {
      const href = this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(this.routerLink.urlTree));
      this.elementRef.nativeElement.href = href;
    }
  }
  /**
   * @internal
   */
  onClick(ev) {
    this.navCtrl.setDirection(this.routerDirection, undefined, undefined, this.routerAnimation);
    /**
     * This prevents the browser from
     * performing a page reload when pressing
     * an Ionic component with routerLink.
     * The page reload interferes with routing
     * and causes ion-back-button to disappear
     * since the local history is wiped on reload.
     */
    ev.preventDefault();
  }
}
/** @nocollapse */
RouterLinkDelegateDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: RouterLinkDelegateDirective,
  deps: [{
    token: i1.LocationStrategy
  }, {
    token: NavController
  }, {
    token: i0.ElementRef
  }, {
    token: i3.Router
  }, {
    token: i3.RouterLink,
    optional: true
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
RouterLinkDelegateDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: RouterLinkDelegateDirective,
  selector: ":not(a):not(area)[routerLink]",
  inputs: {
    routerDirection: "routerDirection",
    routerAnimation: "routerAnimation"
  },
  host: {
    listeners: {
      "click": "onClick($event)"
    }
  },
  usesOnChanges: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: RouterLinkDelegateDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: ':not(a):not(area)[routerLink]'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i1.LocationStrategy
    }, {
      type: NavController
    }, {
      type: i0.ElementRef
    }, {
      type: i3.Router
    }, {
      type: i3.RouterLink,
      decorators: [{
        type: Optional
      }]
    }];
  },
  propDecorators: {
    routerDirection: [{
      type: Input
    }],
    routerAnimation: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  }
});
class RouterLinkWithHrefDelegateDirective {
  constructor(locationStrategy, navCtrl, elementRef, router, routerLink) {
    this.locationStrategy = locationStrategy;
    this.navCtrl = navCtrl;
    this.elementRef = elementRef;
    this.router = router;
    this.routerLink = routerLink;
    this.routerDirection = 'forward';
  }
  ngOnInit() {
    this.updateTargetUrlAndHref();
  }
  ngOnChanges() {
    this.updateTargetUrlAndHref();
  }
  updateTargetUrlAndHref() {
    var _a;
    if ((_a = this.routerLink) === null || _a === void 0 ? void 0 : _a.urlTree) {
      const href = this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(this.routerLink.urlTree));
      this.elementRef.nativeElement.href = href;
    }
  }
  /**
   * @internal
   */
  onClick() {
    this.navCtrl.setDirection(this.routerDirection, undefined, undefined, this.routerAnimation);
  }
}
/** @nocollapse */
RouterLinkWithHrefDelegateDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: RouterLinkWithHrefDelegateDirective,
  deps: [{
    token: i1.LocationStrategy
  }, {
    token: NavController
  }, {
    token: i0.ElementRef
  }, {
    token: i3.Router
  }, {
    token: i3.RouterLink,
    optional: true
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
RouterLinkWithHrefDelegateDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: RouterLinkWithHrefDelegateDirective,
  selector: "a[routerLink],area[routerLink]",
  inputs: {
    routerDirection: "routerDirection",
    routerAnimation: "routerAnimation"
  },
  host: {
    listeners: {
      "click": "onClick()"
    }
  },
  usesOnChanges: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: RouterLinkWithHrefDelegateDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'a[routerLink],area[routerLink]'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i1.LocationStrategy
    }, {
      type: NavController
    }, {
      type: i0.ElementRef
    }, {
      type: i3.Router
    }, {
      type: i3.RouterLink,
      decorators: [{
        type: Optional
      }]
    }];
  },
  propDecorators: {
    routerDirection: [{
      type: Input
    }],
    routerAnimation: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  }
});

/**
 * @hidden
 */
class VirtualFooter {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
/** @nocollapse */
VirtualFooter.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: VirtualFooter,
  deps: [{
    token: i0.TemplateRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
VirtualFooter.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: VirtualFooter,
  selector: "[virtualFooter]",
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: VirtualFooter,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[virtualFooter]'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.TemplateRef
    }];
  }
});

/**
 * @hidden
 */
class VirtualHeader {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
/** @nocollapse */
VirtualHeader.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: VirtualHeader,
  deps: [{
    token: i0.TemplateRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
VirtualHeader.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: VirtualHeader,
  selector: "[virtualHeader]",
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: VirtualHeader,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[virtualHeader]'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.TemplateRef
    }];
  }
});

/**
 * @hidden
 */
class VirtualItem {
  constructor(templateRef, viewContainer) {
    this.templateRef = templateRef;
    this.viewContainer = viewContainer;
  }
}
/** @nocollapse */
VirtualItem.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: VirtualItem,
  deps: [{
    token: i0.TemplateRef
  }, {
    token: i0.ViewContainerRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
VirtualItem.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: VirtualItem,
  selector: "[virtualItem]",
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: VirtualItem,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[virtualItem]'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: i0.ViewContainerRef
    }];
  }
});
let IonVirtualScroll = class IonVirtualScroll {
  constructor(z, iterableDiffers, elementRef) {
    this.z = z;
    this.iterableDiffers = iterableDiffers;
    this.refMap = new WeakMap();
    this.el = elementRef.nativeElement;
    this.el.nodeRender = this.nodeRender.bind(this);
  }
  ngOnChanges(changes) {
    if (this.trackBy && 'items' in changes) {
      // React on virtualScroll changes only once all inputs have been initialized
      const value = changes['items'].currentValue;
      if (this.differ === undefined && value != null) {
        try {
          this.differ = this.iterableDiffers.find(value).create(this.trackBy);
        } catch (e) {
          throw new Error(`Cannot find a differ supporting object '${value}'. VirtualScroll only supports binding to Iterables such as Arrays.`);
        }
      }
    }
  }
  ngDoCheck() {
    // and if there actually are changes
    const changes = this.differ !== undefined && this.items ? this.differ.diff(this.items) : null;
    if (changes === null) {
      return;
    }
    // TODO: optimize
    this.checkRange(0);
  }
  nodeRender(el, cell, index) {
    return this.z.run(() => {
      let node;
      if (!el) {
        node = this.itmTmp.viewContainer.createEmbeddedView(this.getComponent(cell.type), {
          $implicit: cell.value,
          index
        }, index);
        el = getElement(node);
        this.refMap.set(el, node);
      } else {
        node = this.refMap.get(el);
        const ctx = node.context;
        ctx.$implicit = cell.value;
        ctx.index = cell.index;
      }
      // run sync change detections
      node.detectChanges();
      return el;
    });
  }
  getComponent(type) {
    switch (type) {
      case 'item':
        return this.itmTmp.templateRef;
      case 'header':
        return this.hdrTmp.templateRef;
      case 'footer':
        return this.ftrTmp.templateRef;
      default:
        throw new Error('template for virtual item was not provided');
    }
  }
};
/** @nocollapse */
IonVirtualScroll.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonVirtualScroll,
  deps: [{
    token: i0.NgZone
  }, {
    token: i0.IterableDiffers
  }, {
    token: i0.ElementRef
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonVirtualScroll.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonVirtualScroll,
  selector: "ion-virtual-scroll",
  inputs: {
    approxItemHeight: "approxItemHeight",
    approxHeaderHeight: "approxHeaderHeight",
    approxFooterHeight: "approxFooterHeight",
    headerFn: "headerFn",
    footerFn: "footerFn",
    items: "items",
    itemHeight: "itemHeight",
    headerHeight: "headerHeight",
    footerHeight: "footerHeight",
    trackBy: "trackBy"
  },
  queries: [{
    propertyName: "itmTmp",
    first: true,
    predicate: VirtualItem,
    descendants: true
  }, {
    propertyName: "hdrTmp",
    first: true,
    predicate: VirtualHeader,
    descendants: true
  }, {
    propertyName: "ftrTmp",
    first: true,
    predicate: VirtualFooter,
    descendants: true
  }],
  usesOnChanges: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonVirtualScroll = __decorate([ProxyCmp({
  inputs: ['approxItemHeight', 'approxHeaderHeight', 'approxFooterHeight', 'headerFn', 'footerFn', 'items', 'itemHeight', 'headerHeight', 'footerHeight'],
  methods: ['checkEnd', 'checkRange', 'positionForItem']
})], IonVirtualScroll);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonVirtualScroll,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-virtual-scroll',
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush,
      inputs: ['approxItemHeight', 'approxHeaderHeight', 'approxFooterHeight', 'headerFn', 'footerFn', 'items', 'itemHeight', 'headerHeight', 'footerHeight', 'trackBy']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.NgZone
    }, {
      type: i0.IterableDiffers
    }, {
      type: i0.ElementRef
    }];
  },
  propDecorators: {
    itmTmp: [{
      type: ContentChild,
      args: [VirtualItem, {
        static: false
      }]
    }],
    hdrTmp: [{
      type: ContentChild,
      args: [VirtualHeader, {
        static: false
      }]
    }],
    ftrTmp: [{
      type: ContentChild,
      args: [VirtualFooter, {
        static: false
      }]
    }]
  }
});
const getElement = view => {
  const rootNodes = view.rootNodes;
  for (let i = 0; i < rootNodes.length; i++) {
    if (rootNodes[i].nodeType === 1) {
      return rootNodes[i];
    }
  }
  throw new Error('virtual element was not created');
};
let IonModal = class IonModal {
  constructor(c, r, z) {
    this.z = z;
    this.isCmpOpen = false;
    this.el = r.nativeElement;
    this.el.addEventListener('willPresent', () => {
      this.isCmpOpen = true;
      c.detectChanges();
    });
    this.el.addEventListener('didDismiss', () => {
      this.isCmpOpen = false;
      c.detectChanges();
    });
    proxyOutputs(this, this.el, ['ionModalDidPresent', 'ionModalWillPresent', 'ionModalWillDismiss', 'ionModalDidDismiss', 'ionBreakpointDidChange', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonModal.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonModal,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonModal.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonModal,
  selector: "ion-modal",
  inputs: {
    animated: "animated",
    keepContentsMounted: "keepContentsMounted",
    backdropBreakpoint: "backdropBreakpoint",
    backdropDismiss: "backdropDismiss",
    breakpoints: "breakpoints",
    canDismiss: "canDismiss",
    cssClass: "cssClass",
    enterAnimation: "enterAnimation",
    event: "event",
    handle: "handle",
    handleBehavior: "handleBehavior",
    initialBreakpoint: "initialBreakpoint",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    mode: "mode",
    presentingElement: "presentingElement",
    showBackdrop: "showBackdrop",
    swipeToClose: "swipeToClose",
    translucent: "translucent",
    trigger: "trigger"
  },
  queries: [{
    propertyName: "template",
    first: true,
    predicate: TemplateRef,
    descendants: true
  }],
  ngImport: i0,
  template: `<div class="ion-delegate-host ion-page" *ngIf="isCmpOpen || keepContentsMounted">
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </div>`,
  isInline: true,
  directives: [{
    type: i1.NgIf,
    selector: "[ngIf]",
    inputs: ["ngIf", "ngIfThen", "ngIfElse"]
  }, {
    type: i1.NgTemplateOutlet,
    selector: "[ngTemplateOutlet]",
    inputs: ["ngTemplateOutletContext", "ngTemplateOutlet"]
  }],
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonModal = __decorate([ProxyCmp({
  inputs: ['animated', 'keepContentsMounted', 'backdropBreakpoint', 'backdropDismiss', 'breakpoints', 'canDismiss', 'cssClass', 'enterAnimation', 'event', 'handle', 'handleBehavior', 'initialBreakpoint', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'presentingElement', 'showBackdrop', 'swipeToClose', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss', 'setCurrentBreakpoint', 'getCurrentBreakpoint']
})], IonModal);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonModal,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-modal',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: `<div class="ion-delegate-host ion-page" *ngIf="isCmpOpen || keepContentsMounted">
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </div>`,
      inputs: ['animated', 'keepContentsMounted', 'backdropBreakpoint', 'backdropDismiss', 'breakpoints', 'canDismiss', 'cssClass', 'enterAnimation', 'event', 'handle', 'handleBehavior', 'initialBreakpoint', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'presentingElement', 'showBackdrop', 'swipeToClose', 'translucent', 'trigger']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  },
  propDecorators: {
    template: [{
      type: ContentChild,
      args: [TemplateRef, {
        static: false
      }]
    }]
  }
});
let IonPopover = class IonPopover {
  constructor(c, r, z) {
    this.z = z;
    this.isCmpOpen = false;
    this.el = r.nativeElement;
    this.el.addEventListener('ionMount', () => {
      this.isCmpOpen = true;
      c.detectChanges();
    });
    this.el.addEventListener('didDismiss', () => {
      this.isCmpOpen = false;
      c.detectChanges();
    });
    proxyOutputs(this, this.el, ['ionPopoverDidPresent', 'ionPopoverWillPresent', 'ionPopoverWillDismiss', 'ionPopoverDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonPopover.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonPopover,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonPopover.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "12.2.10",
  type: IonPopover,
  selector: "ion-popover",
  inputs: {
    alignment: "alignment",
    animated: "animated",
    arrow: "arrow",
    keepContentsMounted: "keepContentsMounted",
    backdropDismiss: "backdropDismiss",
    cssClass: "cssClass",
    dismissOnSelect: "dismissOnSelect",
    enterAnimation: "enterAnimation",
    event: "event",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    mode: "mode",
    showBackdrop: "showBackdrop",
    translucent: "translucent",
    trigger: "trigger",
    triggerAction: "triggerAction",
    reference: "reference",
    size: "size",
    side: "side"
  },
  queries: [{
    propertyName: "template",
    first: true,
    predicate: TemplateRef,
    descendants: true
  }],
  ngImport: i0,
  template: `<ng-container [ngTemplateOutlet]="template" *ngIf="isCmpOpen || keepContentsMounted"></ng-container>`,
  isInline: true,
  directives: [{
    type: i1.NgIf,
    selector: "[ngIf]",
    inputs: ["ngIf", "ngIfThen", "ngIfElse"]
  }, {
    type: i1.NgTemplateOutlet,
    selector: "[ngTemplateOutlet]",
    inputs: ["ngTemplateOutletContext", "ngTemplateOutlet"]
  }],
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonPopover = __decorate([ProxyCmp({
  inputs: ['alignment', 'animated', 'arrow', 'keepContentsMounted', 'backdropDismiss', 'cssClass', 'dismissOnSelect', 'enterAnimation', 'event', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'showBackdrop', 'translucent', 'trigger', 'triggerAction', 'reference', 'size', 'side'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonPopover);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonPopover,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-popover',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: `<ng-container [ngTemplateOutlet]="template" *ngIf="isCmpOpen || keepContentsMounted"></ng-container>`,
      inputs: ['alignment', 'animated', 'arrow', 'keepContentsMounted', 'backdropDismiss', 'cssClass', 'dismissOnSelect', 'enterAnimation', 'event', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'showBackdrop', 'translucent', 'trigger', 'triggerAction', 'reference', 'size', 'side']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  },
  propDecorators: {
    template: [{
      type: ContentChild,
      args: [TemplateRef, {
        static: false
      }]
    }]
  }
});

// TODO(FW-2827): types
class OverlayBaseController {
  constructor(ctrl) {
    this.ctrl = ctrl;
  }
  /**
   * Creates a new overlay
   */
  create(opts) {
    return this.ctrl.create(opts || {});
  }
  /**
   * When `id` is not provided, it dismisses the top overlay.
   */
  dismiss(data, role, id) {
    return this.ctrl.dismiss(data, role, id);
  }
  /**
   * Returns the top overlay.
   */
  getTop() {
    return this.ctrl.getTop();
  }
}
class ActionSheetController extends OverlayBaseController {
  constructor() {
    super(actionSheetController);
  }
}
/** @nocollapse */
ActionSheetController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: ActionSheetController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
ActionSheetController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: ActionSheetController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: ActionSheetController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class AlertController extends OverlayBaseController {
  constructor() {
    super(alertController);
  }
}
/** @nocollapse */
AlertController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: AlertController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
AlertController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: AlertController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: AlertController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class LoadingController extends OverlayBaseController {
  constructor() {
    super(loadingController);
  }
}
/** @nocollapse */
LoadingController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: LoadingController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
LoadingController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: LoadingController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: LoadingController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class MenuController {
  /**
   * Programmatically open the Menu.
   * @param [menuId]  Optionally get the menu by its id, or side.
   * @return returns a promise when the menu is fully opened
   */
  open(menuId) {
    return menuController.open(menuId);
  }
  /**
   * Programmatically close the Menu. If no `menuId` is given as the first
   * argument then it'll close any menu which is open. If a `menuId`
   * is given then it'll close that exact menu.
   * @param [menuId]  Optionally get the menu by its id, or side.
   * @return returns a promise when the menu is fully closed
   */
  close(menuId) {
    return menuController.close(menuId);
  }
  /**
   * Toggle the menu. If it's closed, it will open, and if opened, it
   * will close.
   * @param [menuId]  Optionally get the menu by its id, or side.
   * @return returns a promise when the menu has been toggled
   */
  toggle(menuId) {
    return menuController.toggle(menuId);
  }
  /**
   * Used to enable or disable a menu. For example, there could be multiple
   * left menus, but only one of them should be able to be opened at the same
   * time. If there are multiple menus on the same side, then enabling one menu
   * will also automatically disable all the others that are on the same side.
   * @param [menuId]  Optionally get the menu by its id, or side.
   * @return Returns the instance of the menu, which is useful for chaining.
   */
  enable(shouldEnable, menuId) {
    return menuController.enable(shouldEnable, menuId);
  }
  /**
   * Used to enable or disable the ability to swipe open the menu.
   * @param shouldEnable  True if it should be swipe-able, false if not.
   * @param [menuId]  Optionally get the menu by its id, or side.
   * @return Returns the instance of the menu, which is useful for chaining.
   */
  swipeGesture(shouldEnable, menuId) {
    return menuController.swipeGesture(shouldEnable, menuId);
  }
  /**
   * @param [menuId] Optionally get the menu by its id, or side.
   * @return Returns true if the specified menu is currently open, otherwise false.
   * If the menuId is not specified, it returns true if ANY menu is currenly open.
   */
  isOpen(menuId) {
    return menuController.isOpen(menuId);
  }
  /**
   * @param [menuId]  Optionally get the menu by its id, or side.
   * @return Returns true if the menu is currently enabled, otherwise false.
   */
  isEnabled(menuId) {
    return menuController.isEnabled(menuId);
  }
  /**
   * Used to get a menu instance. If a `menuId` is not provided then it'll
   * return the first menu found. If a `menuId` is `left` or `right`, then
   * it'll return the enabled menu on that side. Otherwise, if a `menuId` is
   * provided, then it'll try to find the menu using the menu's `id`
   * property. If a menu is not found then it'll return `null`.
   * @param [menuId]  Optionally get the menu by its id, or side.
   * @return Returns the instance of the menu if found, otherwise `null`.
   */
  get(menuId) {
    return menuController.get(menuId);
  }
  /**
   * @return Returns the instance of the menu already opened, otherwise `null`.
   */
  getOpen() {
    return menuController.getOpen();
  }
  /**
   * @return Returns an array of all menu instances.
   */
  getMenus() {
    return menuController.getMenus();
  }
}
/** @nocollapse */
MenuController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: MenuController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
MenuController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: MenuController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: MenuController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }]
});
class PickerController extends OverlayBaseController {
  constructor() {
    super(pickerController);
  }
}
/** @nocollapse */
PickerController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: PickerController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
PickerController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: PickerController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: PickerController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});

/**
 * An `Injector` that's part of the environment injector hierarchy, which exists outside of the
 * component tree.
 *
 * @developerPreview
 */
class EnvironmentInjector {}
class ModalController extends OverlayBaseController {
  constructor(angularDelegate, resolver, injector,
  // TODO: FW-1641: Migrate to Angular's version once Angular 13 is dropped
  environmentInjector) {
    super(modalController);
    this.angularDelegate = angularDelegate;
    this.resolver = resolver;
    this.injector = injector;
    this.environmentInjector = environmentInjector;
  }
  create(opts) {
    var _a;
    return super.create(Object.assign(Object.assign({}, opts), {
      delegate: this.angularDelegate.create((_a = this.resolver) !== null && _a !== void 0 ? _a : this.environmentInjector, this.injector, undefined, 'modal')
    }));
  }
}
/** @nocollapse */
ModalController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: ModalController,
  deps: [{
    token: AngularDelegate
  }, {
    token: i0.ComponentFactoryResolver
  }, {
    token: i0.Injector
  }, {
    token: EnvironmentInjector,
    optional: true
  }],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
ModalController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: ModalController
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: ModalController,
  decorators: [{
    type: Injectable
  }],
  ctorParameters: function () {
    return [{
      type: AngularDelegate
    }, {
      type: i0.ComponentFactoryResolver
    }, {
      type: i0.Injector
    }, {
      type: EnvironmentInjector,
      decorators: [{
        type: Optional
      }]
    }];
  }
});
class PopoverController extends OverlayBaseController {
  constructor(angularDelegate, resolver, injector,
  // TODO: FW-1641: Migrate to Angular's version once Angular 13 is dropped
  environmentInjector) {
    super(popoverController);
    this.angularDelegate = angularDelegate;
    this.resolver = resolver;
    this.injector = injector;
    this.environmentInjector = environmentInjector;
  }
  create(opts) {
    var _a;
    return super.create(Object.assign(Object.assign({}, opts), {
      delegate: this.angularDelegate.create((_a = this.resolver) !== null && _a !== void 0 ? _a : this.environmentInjector, this.injector, undefined, 'popover')
    }));
  }
}
/** @nocollapse */
PopoverController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: PopoverController,
  deps: [{
    token: AngularDelegate
  }, {
    token: i0.ComponentFactoryResolver
  }, {
    token: i0.Injector
  }, {
    token: EnvironmentInjector,
    optional: true
  }],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
PopoverController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: PopoverController
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: PopoverController,
  decorators: [{
    type: Injectable
  }],
  ctorParameters: function () {
    return [{
      type: AngularDelegate
    }, {
      type: i0.ComponentFactoryResolver
    }, {
      type: i0.Injector
    }, {
      type: EnvironmentInjector,
      decorators: [{
        type: Optional
      }]
    }];
  }
});
class ToastController extends OverlayBaseController {
  constructor() {
    super(toastController);
  }
}
/** @nocollapse */
ToastController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: ToastController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
ToastController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: ToastController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: ToastController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class DomController {
  /**
   * Schedules a task to run during the READ phase of the next frame.
   * This task should only read the DOM, but never modify it.
   */
  read(cb) {
    getQueue().read(cb);
  }
  /**
   * Schedules a task to run during the WRITE phase of the next frame.
   * This task should write the DOM, but never READ it.
   */
  write(cb) {
    getQueue().write(cb);
  }
}
/** @nocollapse */
DomController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: DomController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
DomController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: DomController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: DomController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }]
});
const getQueue = () => {
  const win = typeof window !== 'undefined' ? window : null;
  if (win != null) {
    const Ionic = win.Ionic;
    if (Ionic === null || Ionic === void 0 ? void 0 : Ionic.queue) {
      return Ionic.queue;
    }
    return {
      read: cb => win.requestAnimationFrame(cb),
      write: cb => win.requestAnimationFrame(cb)
    };
  }
  return {
    read: cb => cb(),
    write: cb => cb()
  };
};
class AnimationController {
  /**
   * Create a new animation
   */
  create(animationId) {
    return createAnimation(animationId);
  }
  /**
   * EXPERIMENTAL
   *
   * Given a progression and a cubic bezier function,
   * this utility returns the time value(s) at which the
   * cubic bezier reaches the given time progression.
   *
   * If the cubic bezier never reaches the progression
   * the result will be an empty array.
   *
   * This is most useful for switching between easing curves
   * when doing a gesture animation (i.e. going from linear easing
   * during a drag, to another easing when `progressEnd` is called)
   */
  easingTime(p0, p1, p2, p3, progression) {
    return getTimeGivenProgression(p0, p1, p2, p3, progression);
  }
}
/** @nocollapse */
AnimationController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: AnimationController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
AnimationController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: AnimationController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: AnimationController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }]
});
class GestureController {
  constructor(zone) {
    this.zone = zone;
  }
  /**
   * Create a new gesture
   */
  create(opts, runInsideAngularZone = false) {
    if (runInsideAngularZone) {
      Object.getOwnPropertyNames(opts).forEach(key => {
        if (typeof opts[key] === 'function') {
          const fn = opts[key];
          opts[key] = (...props) => this.zone.run(() => fn(...props));
        }
      });
    }
    return createGesture(opts);
  }
}
/** @nocollapse */
GestureController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: GestureController,
  deps: [{
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
GestureController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: GestureController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: GestureController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.NgZone
    }];
  }
});
class IonicRouteStrategy {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  shouldDetach(_route) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  shouldAttach(_route) {
    return false;
  }
  store(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _route,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _detachedTree) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  retrieve(_route) {
    return null;
  }
  shouldReuseRoute(future, curr) {
    if (future.routeConfig !== curr.routeConfig) {
      return false;
    }
    // checking router params
    const futureParams = future.params;
    const currentParams = curr.params;
    const keysA = Object.keys(futureParams);
    const keysB = Object.keys(currentParams);
    if (keysA.length !== keysB.length) {
      return false;
    }
    // Test for A's keys different from B.
    for (const key of keysA) {
      if (currentParams[key] !== futureParams[key]) {
        return false;
      }
    }
    return true;
  }
}

/**
 *  https://ionicframework.com/docs/api/router-outlet#life-cycle-hooks
 */

// TODO(FW-2827): types
const appInitialize = (config, doc, zone) => {
  return () => {
    const win = doc.defaultView;
    if (win && typeof window !== 'undefined') {
      setupConfig(Object.assign(Object.assign({}, config), {
        _zoneGate: h => zone.run(h)
      }));
      const aelFn = '__zone_symbol__addEventListener' in doc.body ? '__zone_symbol__addEventListener' : 'addEventListener';
      return applyPolyfills().then(() => {
        return defineCustomElements(win, {
          exclude: ['ion-tabs', 'ion-tab'],
          syncQueue: true,
          raf,
          jmp: h => zone.runOutsideAngular(h),
          ael(elm, eventName, cb, opts) {
            elm[aelFn](eventName, cb, opts);
          },
          rel(elm, eventName, cb, opts) {
            elm.removeEventListener(eventName, cb, opts);
          }
        });
      });
    }
  };
};
const DIRECTIVES = [IonAccordion, IonAccordionGroup, IonApp, IonAvatar, IonBackButton, IonBackdrop, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonMenu, IonMenuButton, IonMenuToggle, IonNav, IonNavLink, IonNote, IonProgressBar, IonRadio, IonRadioGroup, IonRange, IonRefresher, IonRefresherContent, IonReorder, IonReorderGroup, IonRippleEffect, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSlide, IonSlides, IonSpinner, IonSplitPane, IonTabBar, IonTabButton, IonText, IonTextarea, IonThumbnail, IonTitle, IonToggle, IonToolbar];
const DECLARATIONS = [
// generated proxies
...DIRECTIVES,
// manual proxies
IonModal, IonPopover,
// ngModel accessors
BooleanValueAccessorDirective, NumericValueAccessorDirective, RadioValueAccessorDirective, SelectValueAccessorDirective, TextValueAccessorDirective,
// navigation
IonTabs, IonRouterOutlet, IonBackButtonDelegateDirective, NavDelegate, RouterLinkDelegateDirective, RouterLinkWithHrefDelegateDirective,
// virtual scroll
VirtualFooter, VirtualHeader, VirtualItem, IonVirtualScroll];
class IonicModule {
  static forRoot(config) {
    return {
      ngModule: IonicModule,
      providers: [{
        provide: ConfigToken,
        useValue: config
      }, {
        provide: APP_INITIALIZER,
        useFactory: appInitialize,
        multi: true,
        deps: [ConfigToken, DOCUMENT, NgZone]
      }]
    };
  }
}
/** @nocollapse */
IonicModule.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonicModule,
  deps: [],
  target: i0.ɵɵFactoryTarget.NgModule
});
/** @nocollapse */
IonicModule.ɵmod = i0.ɵɵngDeclareNgModule({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonicModule,
  declarations: [IonAccordion, IonAccordionGroup, IonApp, IonAvatar, IonBackButton, IonBackdrop, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonMenu, IonMenuButton, IonMenuToggle, IonNav, IonNavLink, IonNote, IonProgressBar, IonRadio, IonRadioGroup, IonRange, IonRefresher, IonRefresherContent, IonReorder, IonReorderGroup, IonRippleEffect, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSlide, IonSlides, IonSpinner, IonSplitPane, IonTabBar, IonTabButton, IonText, IonTextarea, IonThumbnail, IonTitle, IonToggle, IonToolbar,
  // manual proxies
  IonModal, IonPopover,
  // ngModel accessors
  BooleanValueAccessorDirective, NumericValueAccessorDirective, RadioValueAccessorDirective, SelectValueAccessorDirective, TextValueAccessorDirective,
  // navigation
  IonTabs, IonRouterOutlet, IonBackButtonDelegateDirective, NavDelegate, RouterLinkDelegateDirective, RouterLinkWithHrefDelegateDirective,
  // virtual scroll
  VirtualFooter, VirtualHeader, VirtualItem, IonVirtualScroll],
  imports: [CommonModule],
  exports: [IonAccordion, IonAccordionGroup, IonApp, IonAvatar, IonBackButton, IonBackdrop, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonMenu, IonMenuButton, IonMenuToggle, IonNav, IonNavLink, IonNote, IonProgressBar, IonRadio, IonRadioGroup, IonRange, IonRefresher, IonRefresherContent, IonReorder, IonReorderGroup, IonRippleEffect, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSlide, IonSlides, IonSpinner, IonSplitPane, IonTabBar, IonTabButton, IonText, IonTextarea, IonThumbnail, IonTitle, IonToggle, IonToolbar,
  // manual proxies
  IonModal, IonPopover,
  // ngModel accessors
  BooleanValueAccessorDirective, NumericValueAccessorDirective, RadioValueAccessorDirective, SelectValueAccessorDirective, TextValueAccessorDirective,
  // navigation
  IonTabs, IonRouterOutlet, IonBackButtonDelegateDirective, NavDelegate, RouterLinkDelegateDirective, RouterLinkWithHrefDelegateDirective,
  // virtual scroll
  VirtualFooter, VirtualHeader, VirtualItem, IonVirtualScroll]
});
/** @nocollapse */
IonicModule.ɵinj = i0.ɵɵngDeclareInjector({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonicModule,
  providers: [AngularDelegate, ModalController, PopoverController],
  imports: [[CommonModule]]
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.10",
  ngImport: i0,
  type: IonicModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: DECLARATIONS,
      exports: DECLARATIONS,
      providers: [AngularDelegate, ModalController, PopoverController],
      imports: [CommonModule]
    }]
  }]
});

// DIRECTIVES

/**
 * Generated bundle index. Do not edit.
 */

export { ActionSheetController, AlertController, AngularDelegate, AnimationController, BooleanValueAccessorDirective as BooleanValueAccessor, Config, DomController, GestureController, IonAccordion, IonAccordionGroup, IonApp, IonAvatar, IonBackButton, IonBackButtonDelegateDirective as IonBackButtonDelegate, IonBackdrop, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonMenu, IonMenuButton, IonMenuToggle, IonModal, IonNav, IonNavLink, IonNote, IonPopover, IonProgressBar, IonRadio, IonRadioGroup, IonRange, IonRefresher, IonRefresherContent, IonReorder, IonReorderGroup, IonRippleEffect, IonRouterOutlet, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSlide, IonSlides, IonSpinner, IonSplitPane, IonTabBar, IonTabButton, IonTabs, IonText, IonTextarea, IonThumbnail, IonTitle, IonToggle, IonToolbar, IonVirtualScroll, IonicModule, IonicRouteStrategy, LoadingController, MenuController, ModalController, NavController, NavDelegate, NavParams, NumericValueAccessorDirective as NumericValueAccessor, PickerController, Platform, PopoverController, RadioValueAccessorDirective as RadioValueAccessor, RouterLinkDelegateDirective as RouterLinkDelegate, RouterLinkWithHrefDelegateDirective as RouterLinkWithHrefDelegate, SelectValueAccessorDirective as SelectValueAccessor, TextValueAccessorDirective as TextValueAccessor, ToastController, VirtualFooter, VirtualHeader, VirtualItem };
